export const data = [
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/C-UpBqUw3hnDJo",
    id: "0001",
    name: "3/4 sit-up",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/5fDKHdgMqjaJWi",
    id: "0002",
    name: "45° side bend",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/gq1VKDha9p4M00",
    id: "0003",
    name: "air bike",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/uyhcJvKbHAhPfZ",
    id: "1512",
    name: "all fours squad stretch",
    target: "quads",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/4xRYHnhMioCFpO",
    id: "0006",
    name: "alternate heel touchers",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/DAMNPpzxMSWk4F",
    id: "0007",
    name: "alternate lateral pulldown",
    target: "lats",
  },
  {
    bodyPart: "lower legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/1dqXb6K-FPsCkx",
    id: "1368",
    name: "ankle circles",
    target: "calves",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/UjWuFFsBv6lsta",
    id: "3293",
    name: "archer pull up",
    target: "lats",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/XtKMJjVB5C-kVf",
    id: "3294",
    name: "archer push up",
    target: "pectorals",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/VyKGYDeBRKghrA",
    id: "2355",
    name: "arm slingers hanging bent knee legs",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/w-e9vXt3kNLNYP",
    id: "2333",
    name: "arm slingers hanging straight legs",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/pUKLK1Uo5MNuvs",
    id: "3214",
    name: "arms apart circular toe touch (male)",
    target: "glutes",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/aZrGJXfg0pMboX",
    id: "3204",
    name: "arms overhead full sit-up (male)",
    target: "abs",
  },
  {
    bodyPart: "chest",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/cYmogT0Us9v232",
    id: "0009",
    name: "assisted chest dip (kneeling)",
    target: "pectorals",
  },
  {
    bodyPart: "waist",
    equipment: "assisted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/TjYfptRS2gNB97",
    id: "0011",
    name: "assisted hanging knee raise",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "assisted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/u2tdaFw8AtEvyy",
    id: "0010",
    name: "assisted hanging knee raise with throw down",
    target: "abs",
  },
  {
    bodyPart: "lower legs",
    equipment: "assisted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/D2DhpFx5jet6y6",
    id: "1708",
    name: "assisted lying calves stretch",
    target: "calves",
  },
  {
    bodyPart: "upper legs",
    equipment: "assisted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/AG09nzJeSThd1h",
    id: "1709",
    name: "assisted lying glutes stretch",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "assisted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/aSx5VeGqk-Bz8I",
    id: "1710",
    name: "assisted lying gluteus and piriformis stretch",
    target: "glutes",
  },
  {
    bodyPart: "waist",
    equipment: "assisted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/VG7SbiHc19dpPO",
    id: "0012",
    name: "assisted lying leg raise with lateral throw down",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "assisted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/LwzAiuwd18nt5M",
    id: "0013",
    name: "assisted lying leg raise with throw down",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "medicine ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/TK1sIXV4ptXiHS",
    id: "0014",
    name: "assisted motion russian twist",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/6YKYmnzgXTLNkf",
    id: "0015",
    name: "assisted parallel close grip pull-up",
    target: "lats",
  },
  {
    bodyPart: "upper legs",
    equipment: "assisted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/M5Bf43k4PzVE6m",
    id: "0016",
    name: "assisted prone hamstring",
    target: "hamstrings",
  },
  {
    bodyPart: "upper legs",
    equipment: "assisted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/KN9rvH03yJGcDj",
    id: "1713",
    name: "assisted prone lying quads stretch",
    target: "quads",
  },
  {
    bodyPart: "waist",
    equipment: "assisted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/EWdIyoVJP2lCPQ",
    id: "1714",
    name: "assisted prone rectus femoris stretch",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/uAHQ6Kj3gQ7zBF",
    id: "0017",
    name: "assisted pull-up",
    target: "lats",
  },
  {
    bodyPart: "chest",
    equipment: "assisted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/e4yGjhMJ4jdoOh",
    id: "1716",
    name: "assisted seated pectoralis major stretch with stability ball",
    target: "pectorals",
  },
  {
    bodyPart: "upper legs",
    equipment: "assisted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/jMBtDARSeKoC5G",
    id: "1712",
    name: "assisted side lying adductor stretch",
    target: "adductors",
  },
  {
    bodyPart: "waist",
    equipment: "assisted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/RSrkI-KsWJFwZp",
    id: "1758",
    name: "assisted sit-up",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/6mv02isfO5v8Ee",
    id: "1431",
    name: "assisted standing chin-up",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/D7zbMxdleNTZ9Y",
    id: "1432",
    name: "assisted standing pull-up",
    target: "lats",
  },
  {
    bodyPart: "upper arms",
    equipment: "assisted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/LCWqgSue4KCUWw",
    id: "0018",
    name: "assisted standing triceps extension (with towel)",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/WK8N6sLz1jS-G2",
    id: "0019",
    name: "assisted triceps dip (kneeling)",
    target: "triceps",
  },
  {
    bodyPart: "chest",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/PylIJgeC3yU1rj",
    id: "2364",
    name: "assisted wide-grip chest dip (kneeling)",
    target: "pectorals",
  },
  {
    bodyPart: "cardio",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/QqVQo8tIePEr3S",
    id: "3220",
    name: "astride jumps (male)",
    target: "cardiovascular system",
  },
  {
    bodyPart: "cardio",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/6rNHzlNNtu3o8H",
    id: "3672",
    name: "back and forth step",
    target: "cardiovascular system",
  },
  {
    bodyPart: "back",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/xQBGi8v5B9y-SY",
    id: "1314",
    name: "back extension on exercise ball",
    target: "spine",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/nJUS-vqYXL9oIG",
    id: "3297",
    name: "back lever",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Q6YNt3sJJSyU9i",
    id: "1405",
    name: "back pec stretch",
    target: "lats",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/fINhN7QEmRFYww",
    id: "1473",
    name: "backward jump",
    target: "quads",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ysTujllmufBws-",
    id: "0020",
    name: "balance board",
    target: "quads",
  },
  {
    bodyPart: "upper arms",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/m8N98vysfGrBFk",
    id: "0968",
    name: "band alternating biceps curl",
    target: "biceps",
  },
  {
    bodyPart: "waist",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/96iAcFYgpzWuU-",
    id: "0969",
    name: "band alternating v-up",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/5nu9iPjvxUrz24",
    id: "0970",
    name: "band assisted pull-up",
    target: "lats",
  },
  {
    bodyPart: "waist",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/pDeAuFykWWbxer",
    id: "0971",
    name: "band assisted wheel rollerout",
    target: "abs",
  },
  {
    bodyPart: "chest",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/b9NHHKegtrTAJT",
    id: "1254",
    name: "band bench press",
    target: "pectorals",
  },
  {
    bodyPart: "upper legs",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/JVO7emy8KtBAvo",
    id: "0980",
    name: "band bent-over hip extension",
    target: "glutes",
  },
  {
    bodyPart: "waist",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/c9zoLREz83NKaB",
    id: "0972",
    name: "band bicycle crunch",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kt5r70ss6gySS3",
    id: "0974",
    name: "band close-grip pulldown",
    target: "lats",
  },
  {
    bodyPart: "upper arms",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ltpkTvB2xUXyaA",
    id: "0975",
    name: "band close-grip push-up",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Jwd666BkFv4uAM",
    id: "0976",
    name: "band concentration curl",
    target: "biceps",
  },
  {
    bodyPart: "back",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/OkCHPOokK68DNj",
    id: "3117",
    name: "band fixed back close grip pulldown",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/puzaqWul7a8izO",
    id: "3116",
    name: "band fixed back underhand pulldown",
    target: "lats",
  },
  {
    bodyPart: "shoulders",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/MGRhlBFUV-Fp3X",
    id: "0977",
    name: "band front lateral raise",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/5mq57psWMYwBCw",
    id: "0978",
    name: "band front raise",
    target: "delts",
  },
  {
    bodyPart: "upper legs",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/i6aNOSzLROx4i7",
    id: "1408",
    name: "band hip lift",
    target: "glutes",
  },
  {
    bodyPart: "waist",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/EVVX5soEAzdN8Z",
    id: "0979",
    name: "band horizontal pallof press",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/KLbtEss8KpFdMs",
    id: "0981",
    name: "band jack knife sit-up",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/UulZCsbXtXcZQ0",
    id: "0983",
    name: "band kneeling one arm pulldown",
    target: "lats",
  },
  {
    bodyPart: "waist",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/8OQXm5rrn4fh6O",
    id: "0985",
    name: "band kneeling twisting crunch",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/VuYEj3G2poWtU6",
    id: "0984",
    name: "band lying hip internal rotation",
    target: "glutes",
  },
  {
    bodyPart: "waist",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/VhzRjRhHw8sVpy",
    id: "1002",
    name: "band lying straight leg raise",
    target: "abs",
  },
  {
    bodyPart: "upper arms",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/t2PooMcCUyDOqR",
    id: "0986",
    name: "band one arm overhead biceps curl",
    target: "biceps",
  },
  {
    bodyPart: "upper legs",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/L9runP2wocWA29",
    id: "0987",
    name: "band one arm single leg split squat",
    target: "quads",
  },
  {
    bodyPart: "back",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/QXztlgFE9HX5eA",
    id: "0988",
    name: "band one arm standing low row",
    target: "upper back",
  },
  {
    bodyPart: "chest",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/v7NIJKrL1Sy5Te",
    id: "0989",
    name: "band one arm twisting chest press",
    target: "pectorals",
  },
  {
    bodyPart: "back",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/lIp3oUZER2Lg9U",
    id: "0990",
    name: "band one arm twisting seated row",
    target: "upper back",
  },
  {
    bodyPart: "upper legs",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/hYXDMPkcvWf6k-",
    id: "0991",
    name: "band pull through",
    target: "glutes",
  },
  {
    bodyPart: "waist",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/MLvYTwcNrFE3mg",
    id: "0992",
    name: "band push sit-up",
    target: "abs",
  },
  {
    bodyPart: "shoulders",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/BV6GF5HjrV5oC0",
    id: "0993",
    name: "band reverse fly",
    target: "delts",
  },
  {
    bodyPart: "lower arms",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/YLZoN8-8AuJSuu",
    id: "0994",
    name: "band reverse wrist curl",
    target: "forearms",
  },
  {
    bodyPart: "upper legs",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/9mSlxtwE3lCk8B",
    id: "0996",
    name: "band seated hip internal rotation",
    target: "glutes",
  },
  {
    bodyPart: "waist",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/2PlpevpsUpMMFS",
    id: "1011",
    name: "band seated twist",
    target: "abs",
  },
  {
    bodyPart: "shoulders",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/MSE8sktSPKpaKT",
    id: "0997",
    name: "band shoulder press",
    target: "delts",
  },
  {
    bodyPart: "back",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kh102fWuE0NjKV",
    id: "1018",
    name: "band shrug",
    target: "traps",
  },
  {
    bodyPart: "upper arms",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/JdYT-S3yjw7J3r",
    id: "0998",
    name: "band side triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "lower legs",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/2VpWPbL9v6IzKZ",
    id: "0999",
    name: "band single leg calf raise",
    target: "calves",
  },
  {
    bodyPart: "lower legs",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/-pIDS9nPwiU71p",
    id: "1000",
    name: "band single leg reverse calf raise",
    target: "calves",
  },
  {
    bodyPart: "upper legs",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/4GHiNbT4amXAGS",
    id: "1001",
    name: "band single leg split squat",
    target: "quads",
  },
  {
    bodyPart: "upper legs",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/wWq9GrRw2uvfAK",
    id: "1004",
    name: "band squat",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/4zbnSIEZLolBkZ",
    id: "1003",
    name: "band squat row",
    target: "glutes",
  },
  {
    bodyPart: "waist",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/JLivThRKP4Zcwe",
    id: "1005",
    name: "band standing crunch",
    target: "abs",
  },
  {
    bodyPart: "shoulders",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/I87AEFCBS4vcWN",
    id: "1022",
    name: "band standing rear delt row",
    target: "delts",
  },
  {
    bodyPart: "waist",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/CwVWG-svrgvX6B",
    id: "1007",
    name: "band standing twisting crunch",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Q3bZOGehktfplc",
    id: "1008",
    name: "band step-up",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/gOME2F5Qn8W6py",
    id: "1009",
    name: "band stiff leg deadlift",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/1PxhtpkqAhY1Fx",
    id: "1023",
    name: "band straight back stiff leg deadlift",
    target: "glutes",
  },
  {
    bodyPart: "back",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/LtPe4Uz1o9qy-g",
    id: "1010",
    name: "band straight leg deadlift",
    target: "spine",
  },
  {
    bodyPart: "shoulders",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/KdAACQQZFin7Nq",
    id: "1012",
    name: "band twisting overhead press",
    target: "delts",
  },
  {
    bodyPart: "lower legs",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kBVXBj7Eoh4hig",
    id: "1369",
    name: "band two legs calf raise - (band under both legs) v. 2",
    target: "calves",
  },
  {
    bodyPart: "back",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/whsLIy8pb1wdcE",
    id: "1013",
    name: "band underhand pulldown",
    target: "lats",
  },
  {
    bodyPart: "waist",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/jWLfZseTODB0IF",
    id: "1014",
    name: "band v-up",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/zymJimk2zy7yWR",
    id: "1015",
    name: "band vertical pallof press",
    target: "abs",
  },
  {
    bodyPart: "lower arms",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/4jJOILdQC1lcJp",
    id: "1016",
    name: "band wrist curl",
    target: "forearms",
  },
  {
    bodyPart: "shoulders",
    equipment: "band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/wqdG9Pk1Gm-Arm",
    id: "1017",
    name: "band y-raise",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/MdDus3tu3PmNw6",
    id: "0023",
    name: "barbell alternate biceps curl",
    target: "biceps",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/XwXa-tKwKGLabx",
    id: "0024",
    name: "barbell bench front squat",
    target: "quads",
  },
  {
    bodyPart: "chest",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/KWddWX3lhfHDFJ",
    id: "0025",
    name: "barbell bench press",
    target: "pectorals",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ABkYyRODlmKa9r",
    id: "0026",
    name: "barbell bench squat",
    target: "quads",
  },
  {
    bodyPart: "back",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/bhHr47ZvCeYZJt",
    id: "1316",
    name: "barbell bent arm pullover",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/c18JH3lXrkUIXX",
    id: "0027",
    name: "barbell bent over row",
    target: "upper back",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/TjQfOF4X2VnzrY",
    id: "2407",
    name: "barbell biceps curl (with arm blaster)",
    target: "biceps",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/KRQ20w6uAyrPWi",
    id: "0028",
    name: "barbell clean and press",
    target: "quads",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/NfNPCaqGN8CuZJ",
    id: "0029",
    name: "barbell clean-grip front squat",
    target: "glutes",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/8k7FcaK4NvrPX1",
    id: "0030",
    name: "barbell close-grip bench press",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/DqI7Ja0gVPtzHq",
    id: "0031",
    name: "barbell curl",
    target: "biceps",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ZLEX43svcjgsMX",
    id: "0032",
    name: "barbell deadlift",
    target: "glutes",
  },
  {
    bodyPart: "chest",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/3L29wfzUqz0HmV",
    id: "0033",
    name: "barbell decline bench press",
    target: "pectorals",
  },
  {
    bodyPart: "back",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/aGk1sRAZWI156l",
    id: "0034",
    name: "barbell decline bent arm pullover",
    target: "lats",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/lZucCMdvHOVYOm",
    id: "0035",
    name: "barbell decline close grip to skull press",
    target: "triceps",
  },
  {
    bodyPart: "chest",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/R6GafU0w4-zLrN",
    id: "1255",
    name: "barbell decline pullover",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qzGuZeluaQj8FW",
    id: "0036",
    name: "barbell decline wide-grip press",
    target: "pectorals",
  },
  {
    bodyPart: "back",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/22gx0UUSmAfOrt",
    id: "0037",
    name: "barbell decline wide-grip pullover",
    target: "lats",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kxZ-zK5GKHuTtm",
    id: "0038",
    name: "barbell drag curl",
    target: "biceps",
  },
  {
    bodyPart: "lower legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/1UQ6kA5k6u8-sQ",
    id: "1370",
    name: "barbell floor calf raise",
    target: "calves",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ejVEXrlaujjHZO",
    id: "0039",
    name: "barbell front chest squat",
    target: "glutes",
  },
  {
    bodyPart: "shoulders",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/LXZCq9--ojd4vS",
    id: "0041",
    name: "barbell front raise",
    target: "delts",
  },
  {
    bodyPart: "chest",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/vjr2OXn77j4eSd",
    id: "0040",
    name: "barbell front raise and pullover",
    target: "pectorals",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Hkl5MIdJj9jiKe",
    id: "0042",
    name: "barbell front squat",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/xKQ-qpLboWBjxb",
    id: "0043",
    name: "barbell full squat",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/79mltydCA8eG4Q",
    id: "1461",
    name: "barbell full squat (back pov)",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/sWpaGUCkHfShpW",
    id: "1462",
    name: "barbell full squat (side pov)",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kqsR3fwX5f6oZX",
    id: "1545",
    name: "barbell full zercher squat",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/NU9lBx7uDpTKjj",
    id: "1409",
    name: "barbell glute bridge",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ZfsyHl17Hgz001",
    id: "3562",
    name: "barbell glute bridge two legs on bench (male)",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/9pvg5Q8MSnu45k",
    id: "0044",
    name: "barbell good morning",
    target: "hamstrings",
  },
  {
    bodyPart: "chest",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/C7f8unr2WrZOfH",
    id: "0045",
    name: "barbell guillotine bench press",
    target: "pectorals",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/2uE7CRtGUNSsYp",
    id: "0046",
    name: "barbell hack squat",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/7GEg23gFt0F8KN",
    id: "1436",
    name: "barbell high bar squat",
    target: "glutes",
  },
  {
    bodyPart: "chest",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/eEwlfqlJn0JtPm",
    id: "0047",
    name: "barbell incline bench press",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/L-4Y7a9r7-HPYQ",
    id: "1719",
    name: "barbell incline close grip bench press",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/hw-JA6G-JjqwfQ",
    id: "0048",
    name: "barbell incline reverse-grip press",
    target: "triceps",
  },
  {
    bodyPart: "back",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/eFexuDETGihnYy",
    id: "0049",
    name: "barbell incline row",
    target: "upper back",
  },
  {
    bodyPart: "chest",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/YTsVkCr4UgnLgD",
    id: "0050",
    name: "barbell incline shoulder raise",
    target: "serratus anterior",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/M-KEYM-AoAuXyu",
    id: "0051",
    name: "barbell jefferson squat",
    target: "glutes",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/bDlT0V3mnnrBd0",
    id: "0052",
    name: "barbell jm bench press",
    target: "triceps",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/3wWRPJ4cC9raMM",
    id: "0053",
    name: "barbell jump squat",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/DQr-edlctUt716",
    id: "1410",
    name: "barbell lateral lunge",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/1n5Ya30qVthXnm",
    id: "1435",
    name: "barbell low bar squat",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/QVIBmuBsMuHh4f",
    id: "0054",
    name: "barbell lunge",
    target: "glutes",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/eoDkcWvWMttKHW",
    id: "1720",
    name: "barbell lying back of the head tricep extension",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/aGluvd6MFrsTxc",
    id: "0055",
    name: "barbell lying close-grip press",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/i4xFtaiezF5GOW",
    id: "0056",
    name: "barbell lying close-grip triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kqBdvjJCQ2nka8",
    id: "0057",
    name: "barbell lying extension",
    target: "triceps",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/i70ko2KFDSPRfO",
    id: "0058",
    name: "barbell lying lifting (on hip)",
    target: "glutes",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/XjbboC9PEv8ayo",
    id: "0059",
    name: "barbell lying preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qHDcPahhwgRGnO",
    id: "0061",
    name: "barbell lying triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/XHdo5Mqx8ipXgJ",
    id: "0060",
    name: "barbell lying triceps extension skull crusher",
    target: "triceps",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/yytFrSab5wAk4b",
    id: "0063",
    name: "barbell narrow stance squat",
    target: "glutes",
  },
  {
    bodyPart: "back",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/EDJLqo6eOTC51L",
    id: "0064",
    name: "barbell one arm bent over row",
    target: "upper back",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/0DIct-eSguVxhB",
    id: "0065",
    name: "barbell one arm floor press",
    target: "triceps",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/RMHfFWjihFRvWt",
    id: "0066",
    name: "barbell one arm side deadlift",
    target: "glutes",
  },
  {
    bodyPart: "shoulders",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/vuVLBOdatKIeSI",
    id: "0067",
    name: "barbell one arm snatch",
    target: "delts",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/iUGahEiIpDEJ7m",
    id: "0068",
    name: "barbell one leg squat",
    target: "quads",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/JXo8p0l5dJwS7y",
    id: "0069",
    name: "barbell overhead squat",
    target: "quads",
  },
  {
    bodyPart: "lower arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/lennnq9Tv8kJ6q",
    id: "1411",
    name: "barbell palms down wrist curl over a bench",
    target: "forearms",
  },
  {
    bodyPart: "lower arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qtdmid9hujObyc",
    id: "1412",
    name: "barbell palms up wrist curl over a bench",
    target: "forearms",
  },
  {
    bodyPart: "back",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/-8E0a8K7gg3kUa",
    id: "3017",
    name: "barbell pendlay row",
    target: "upper back",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/0C3v0sjaaUzIna",
    id: "1751",
    name: "barbell pin presses",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/29PW9TU0-3ULfk",
    id: "0070",
    name: "barbell preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "waist",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/VCj5Fo6mE1gr42",
    id: "0071",
    name: "barbell press sit-up",
    target: "abs",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/4MEmurE8GJOU9V",
    id: "0072",
    name: "barbell prone incline curl",
    target: "biceps",
  },
  {
    bodyPart: "back",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/u4s7laczkurtSj",
    id: "0073",
    name: "barbell pullover",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/1Mk0oCAm2EoefJ",
    id: "0022",
    name: "barbell pullover to press",
    target: "lats",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/2xzot1PUTmNzuB",
    id: "0074",
    name: "barbell rack pull",
    target: "glutes",
  },
  {
    bodyPart: "shoulders",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/9w4P1Nhqe4s48C",
    id: "0075",
    name: "barbell rear delt raise",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/i1neITRGjBLXKs",
    id: "0076",
    name: "barbell rear delt row",
    target: "delts",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/GPBf1QG8zgDCqO",
    id: "0078",
    name: "barbell rear lunge",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/fIkMIlHCo4sBWr",
    id: "0077",
    name: "barbell rear lunge v. 2",
    target: "glutes",
  },
  {
    bodyPart: "lower arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/QEFBeYW6mVl8vK",
    id: "0079",
    name: "barbell revers wrist curl v. 2",
    target: "forearms",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ltwkHJHJy74UoK",
    id: "2187",
    name: "barbell reverse close-grip bench press",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Xkxk-95U2fLimY",
    id: "0080",
    name: "barbell reverse curl",
    target: "biceps",
  },
  {
    bodyPart: "back",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Rdsqk9Qg3XQe20",
    id: "0118",
    name: "barbell reverse grip bent over row",
    target: "upper back",
  },
  {
    bodyPart: "chest",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/vH-3keaTovPTEZ",
    id: "1256",
    name: "barbell reverse grip decline bench press",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/zKERwvKVi7oCRu",
    id: "1257",
    name: "barbell reverse grip incline bench press",
    target: "pectorals",
  },
  {
    bodyPart: "back",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/tlpW9x16CABdAK",
    id: "1317",
    name: "barbell reverse grip incline bench row",
    target: "upper back",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/9DunKw5pGp-bPc",
    id: "1721",
    name: "barbell reverse grip skullcrusher",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/pFXagix83gQW-z",
    id: "0081",
    name: "barbell reverse preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "lower arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/2z4FmrVVy6uf2X",
    id: "0082",
    name: "barbell reverse wrist curl",
    target: "forearms",
  },
  {
    bodyPart: "waist",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Fll3gQtvkmjHfv",
    id: "0084",
    name: "barbell rollerout",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/l7d4L4-3dF0Tsb",
    id: "0083",
    name: "barbell rollerout from bench",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/aKlhtKJB7lZZUg",
    id: "0085",
    name: "barbell romanian deadlift",
    target: "glutes",
  },
  {
    bodyPart: "shoulders",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/rwEBE6ajIJRdBX",
    id: "0086",
    name: "barbell seated behind head military press",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/9Eae8Fd32IJwnh",
    id: "0087",
    name: "barbell seated bradford rocky press",
    target: "delts",
  },
  {
    bodyPart: "lower legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/KNbYVUYVhjdkBr",
    id: "0088",
    name: "barbell seated calf raise",
    target: "calves",
  },
  {
    bodyPart: "lower legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/9Dk3DiRUqlkQQP",
    id: "1371",
    name: "barbell seated calf raise",
    target: "calves",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/E8NHASTt3CcG-H",
    id: "1718",
    name: "barbell seated close grip behind neck triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/5ZpBtJbbxfFlhJ",
    id: "0089",
    name: "barbell seated close-grip concentration curl",
    target: "biceps",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/sx3wvxCKNZHK7Q",
    id: "0090",
    name: "barbell seated good morning",
    target: "glutes",
  },
  {
    bodyPart: "shoulders",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/UX3yyRJeBQkdjZ",
    id: "0091",
    name: "barbell seated overhead press",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/opMj0BQMUbO3jH",
    id: "0092",
    name: "barbell seated overhead triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "waist",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/TIJ8e4gTvmv8Gg",
    id: "0094",
    name: "barbell seated twist",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/p4x80-C80b6PED",
    id: "0095",
    name: "barbell shrug",
    target: "traps",
  },
  {
    bodyPart: "waist",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/JoKlD5GI8GpH4V",
    id: "0096",
    name: "barbell side bent v. 2",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ce-rNp5WR08rGk",
    id: "0098",
    name: "barbell side split squat",
    target: "quads",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/sUNBtfk9ggW7ZT",
    id: "0097",
    name: "barbell side split squat v. 2",
    target: "quads",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/H7QvhaSSEYMyVT",
    id: "1756",
    name: "barbell single leg deadlift",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/RMFYM0mlyPcDVp",
    id: "0099",
    name: "barbell single leg split squat",
    target: "quads",
  },
  {
    bodyPart: "waist",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/yo3lW8Zu5wMi4q",
    id: "2799",
    name: "barbell sitted alternate leg raise",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/GOAmpxchHeYU9w",
    id: "2800",
    name: "barbell sitted alternate leg raise (female)",
    target: "abs",
  },
  {
    bodyPart: "shoulders",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/2uMIV7GQX3Zwuo",
    id: "0100",
    name: "barbell skier",
    target: "delts",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/hXOLv4YT4ixUji",
    id: "0101",
    name: "barbell speed squat",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kvBUXuPiPMZl0D",
    id: "2810",
    name: "barbell split squat v. 2",
    target: "quads",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/-5q7oBAYu1o52v",
    id: "0102",
    name: "barbell squat (on knees)",
    target: "quads",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/44KvDwi0LJt9M6",
    id: "2798",
    name: "barbell squat jump step rear lunge",
    target: "quads",
  },
  {
    bodyPart: "waist",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/pTd6cgh4tYtFYw",
    id: "0103",
    name: "barbell standing ab rollerout",
    target: "abs",
  },
  {
    bodyPart: "lower arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ZwPoeqsaQour2P",
    id: "0104",
    name: "barbell standing back wrist curl",
    target: "forearms",
  },
  {
    bodyPart: "shoulders",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/mvhBkR8JJTr-pn",
    id: "0105",
    name: "barbell standing bradford press",
    target: "delts",
  },
  {
    bodyPart: "lower legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qa0EoUpJt2tc9K",
    id: "1372",
    name: "barbell standing calf raise",
    target: "calves",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/AkQHZ8dpLwymeV",
    id: "0106",
    name: "barbell standing close grip curl",
    target: "biceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/k2jkWy0MMRFKfj",
    id: "1456",
    name: "barbell standing close grip military press",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/r4GxtbV74H0fMj",
    id: "2414",
    name: "barbell standing concentration curl",
    target: "biceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/bDTr1XCTzkNRPq",
    id: "0107",
    name: "barbell standing front raise over head",
    target: "delts",
  },
  {
    bodyPart: "lower legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/PsXNlXpyt79XF0",
    id: "0108",
    name: "barbell standing leg calf raise",
    target: "calves",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/X9IOUAUfa7gV4e",
    id: "0109",
    name: "barbell standing overhead triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/AFyyPtKj6LfHok",
    id: "0110",
    name: "barbell standing reverse grip curl",
    target: "biceps",
  },
  {
    bodyPart: "lower legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Kn34yWwdtt0zx3",
    id: "0111",
    name: "barbell standing rocking leg calf raise",
    target: "calves",
  },
  {
    bodyPart: "waist",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/DKYcnQvQNgPNGP",
    id: "0112",
    name: "barbell standing twist",
    target: "abs",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/u624AUyWaGk8FP",
    id: "1629",
    name: "barbell standing wide grip biceps curl",
    target: "biceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/L4ZAHAnCA5l9uO",
    id: "1457",
    name: "barbell standing wide military press",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/cjwQFZaEjeeKpi",
    id: "0113",
    name: "barbell standing wide-grip curl",
    target: "biceps",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/SYhaSqvUHPyat9",
    id: "0114",
    name: "barbell step-up",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ysWUfUPXL6EleC",
    id: "0115",
    name: "barbell stiff leg good morning",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/jWoFyVXfKnMkF7",
    id: "0116",
    name: "barbell straight leg deadlift",
    target: "hamstrings",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/yNVjAPUcZMhTOg",
    id: "0117",
    name: "barbell sumo deadlift",
    target: "glutes",
  },
  {
    bodyPart: "shoulders",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/FhTNk9qrItbGRj",
    id: "3305",
    name: "barbell thruster",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/jLx6Ne9OL9ThlD",
    id: "0120",
    name: "barbell upright row",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/KTBi8GTobWvDwh",
    id: "0119",
    name: "barbell upright row v. 2",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/nwa2ARBZcBvn8J",
    id: "0121",
    name: "barbell upright row v. 3",
    target: "delts",
  },
  {
    bodyPart: "chest",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/PdbvbFCmDku2YC",
    id: "0122",
    name: "barbell wide bench press",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/joQx2GWBSZJEO3",
    id: "1258",
    name: "barbell wide reverse grip bench press",
    target: "pectorals",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Uud1jkA67yFQZf",
    id: "0124",
    name: "barbell wide squat",
    target: "quads",
  },
  {
    bodyPart: "shoulders",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/7nuNZtIES64Z6e",
    id: "0123",
    name: "barbell wide-grip upright row",
    target: "delts",
  },
  {
    bodyPart: "lower arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/aloi16qej3H1fQ",
    id: "0126",
    name: "barbell wrist curl",
    target: "forearms",
  },
  {
    bodyPart: "lower arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/EaWhxA8skMYHPY",
    id: "0125",
    name: "barbell wrist curl v. 2",
    target: "forearms",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/v8qjO1OwFcxba9",
    id: "0127",
    name: "barbell zercher squat",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Qoman271AgD0Af",
    id: "3212",
    name: "basic toe touch (male)",
    target: "glutes",
  },
  {
    bodyPart: "shoulders",
    equipment: "rope",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/XX5I3JafAstMrf",
    id: "0128",
    name: "battling ropes",
    target: "delts",
  },
  {
    bodyPart: "cardio",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/MtECtKF9S7OWdy",
    id: "3360",
    name: "bear crawl",
    target: "cardiovascular system",
  },
  {
    bodyPart: "chest",
    equipment: "assisted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/KnzkdBLRqCEdyR",
    id: "1259",
    name: "behind head chest stretch",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ohs7MuaOiIqfj-",
    id: "0129",
    name: "bench dip (knees bent)",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/GBtobaT2i9DdAc",
    id: "1399",
    name: "bench dip on floor",
    target: "triceps",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/bUs4vsMvFgsi85",
    id: "0130",
    name: "bench hip extension",
    target: "glutes",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/cCiO40RvBr0iMX",
    id: "3019",
    name: "bench pull-ups",
    target: "lats",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/C6QaEDkowjPkRB",
    id: "3639",
    name: "bent knee lying twist (male)",
    target: "glutes",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/g6Pi2DMUfVPjcW",
    id: "1770",
    name: "biceps leg concentration curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/MU2DNTfegEBkc7",
    id: "0139",
    name: "biceps narrow pull-ups",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/vaVvVOil2BhY0P",
    id: "0140",
    name: "biceps pull-up",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/CNe9YOCcQxACk4",
    id: "0137",
    name: "body-up",
    target: "triceps",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/nh9hGNE2hfO8dE",
    id: "3543",
    name: "bodyweight drop jump squat",
    target: "glutes",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/bUvyyxSFtLv0hY",
    id: "3544",
    name: "bodyweight incline side plank",
    target: "abs",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/xbI10SEF-ju3ff",
    id: "1771",
    name: "bodyweight kneeling triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/S600RlEhwk6lC6",
    id: "1769",
    name: "bodyweight side lying biceps curl",
    target: "biceps",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/X8wyq4hNrwr2Pu",
    id: "3168",
    name: "bodyweight squatting row",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/PjyCN3LxcQez1-",
    id: "3167",
    name: "bodyweight squatting row (with towel)",
    target: "upper back",
  },
  {
    bodyPart: "lower legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/umVNMUf7MGeSUH",
    id: "1373",
    name: "bodyweight standing calf raise",
    target: "calves",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/WXmBFa6sYtDFxa",
    id: "3156",
    name: "bodyweight standing close-grip one arm row",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/3ArTUdyvfYO3FZ",
    id: "3158",
    name: "bodyweight standing close-grip row",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Vy1SccaakwvBWR",
    id: "3162",
    name: "bodyweight standing one arm row",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/UAGmKlSRXudzrU",
    id: "3161",
    name: "bodyweight standing one arm row (with towel)",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/1bt93BSQm2YVYV",
    id: "3166",
    name: "bodyweight standing row",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/wqMbA6vSr7sUjA",
    id: "3165",
    name: "bodyweight standing row (with towel)",
    target: "upper back",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ZmeaueQyMUhpwx",
    id: "0138",
    name: "bottoms-up",
    target: "abs",
  },
  {
    bodyPart: "lower legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/YkAmSZji69I6hL",
    id: "1374",
    name: "box jump down with one leg stabilization",
    target: "calves",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/m6BKCwHlhr1snt",
    id: "2466",
    name: "bridge - mountain climber (cross body)",
    target: "abs",
  },
  {
    bodyPart: "cardio",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/93ufKkJ63UaBiS",
    id: "1160",
    name: "burpee",
    target: "cardiovascular system",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/rS6L5jZep42dfs",
    id: "0870",
    name: "butt-ups",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/OIMOIia8xAuUIp",
    id: "1494",
    name: "butterfly yoga pose",
    target: "adductors",
  },
  {
    bodyPart: "shoulders",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/5arR04FYbCB5nI",
    id: "0148",
    name: "cable alternate shoulder press",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/VxtY6FBJyi2Tz7",
    id: "0149",
    name: "cable alternate triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "upper legs",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/PdwMl6pcGTX5Kn",
    id: "3235",
    name: "cable assisted inverse leg curl",
    target: "hamstrings",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/0eYDKehl04P-oI",
    id: "0150",
    name: "cable bar lateral pulldown",
    target: "lats",
  },
  {
    bodyPart: "chest",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/AQscYrNNwVzBqO",
    id: "0151",
    name: "cable bench press",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ORXTz01nTlNVce",
    id: "1630",
    name: "cable close grip curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/QdM49AXuEeg77S",
    id: "1631",
    name: "cable concentration curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/wQN7f77b3hEd2E",
    id: "0152",
    name: "cable concentration extension (on knee)",
    target: "triceps",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/zfx8w9LZ6mr7a-",
    id: "0153",
    name: "cable cross-over lateral pulldown",
    target: "lats",
  },
  {
    bodyPart: "shoulders",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ZXcPtJbiXc0OtW",
    id: "0154",
    name: "cable cross-over revers fly",
    target: "delts",
  },
  {
    bodyPart: "chest",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/pWpiHdlitC3qlr",
    id: "0155",
    name: "cable cross-over variation",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/5PWdp5Yj1O832P",
    id: "0868",
    name: "cable curl",
    target: "biceps",
  },
  {
    bodyPart: "upper legs",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/yYZI9f5V2ECFQk",
    id: "0157",
    name: "cable deadlift",
    target: "glutes",
  },
  {
    bodyPart: "chest",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/8fgC7ibeyE3nnG",
    id: "0158",
    name: "cable decline fly",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/1OJDGZBMxIuYaH",
    id: "1260",
    name: "cable decline one arm press",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/rrSMZYOnuS1Wnr",
    id: "1261",
    name: "cable decline press",
    target: "pectorals",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/WpJyNwqeEqOL9a",
    id: "0159",
    name: "cable decline seated wide-grip row",
    target: "upper back",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/gchTLUyGpMMHWr",
    id: "1632",
    name: "cable drag curl",
    target: "biceps",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/docfHHOUzmh0Sd",
    id: "0160",
    name: "cable floor seated wide-grip row",
    target: "upper back",
  },
  {
    bodyPart: "shoulders",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/8TEljGmAAjSLk1",
    id: "0161",
    name: "cable forward raise",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/tplOqb6MCWy53-",
    id: "0162",
    name: "cable front raise",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/BPx1sxLeE3VJDF",
    id: "0164",
    name: "cable front shoulder raise",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/YdzNNpm-Zbs7Zc",
    id: "0165",
    name: "cable hammer curl (with rope)",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/MF6AekUN5dDSS2",
    id: "1722",
    name: "cable high pulley overhead tricep extension",
    target: "triceps",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/iDTMhltDQBJTJa",
    id: "0167",
    name: "cable high row (kneeling)",
    target: "upper back",
  },
  {
    bodyPart: "upper legs",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/viv9IOKEpLX3OI",
    id: "0168",
    name: "cable hip adduction",
    target: "adductors",
  },
  {
    bodyPart: "chest",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/GiJMGl2MXmFSDb",
    id: "0169",
    name: "cable incline bench press",
    target: "pectorals",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/5nXDidYJSx7f8T",
    id: "1318",
    name: "cable incline bench row",
    target: "upper back",
  },
  {
    bodyPart: "chest",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/mzWGZp1qF7zDM4",
    id: "0171",
    name: "cable incline fly",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/lsyK5UC8QPonPV",
    id: "0170",
    name: "cable incline fly (on stability ball)",
    target: "pectorals",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/w26fZ7BWQFUjkI",
    id: "0172",
    name: "cable incline pushdown",
    target: "lats",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/X76Ux-Sx2DeMH8",
    id: "0173",
    name: "cable incline triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "waist",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/VwywS2WTEiRFex",
    id: "0174",
    name: "cable judo flip",
    target: "abs",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/GCdMQMrAoa2zdd",
    id: "0860",
    name: "cable kickback",
    target: "triceps",
  },
  {
    bodyPart: "waist",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/GmouLs6SwiRVH9",
    id: "0175",
    name: "cable kneeling crunch",
    target: "abs",
  },
  {
    bodyPart: "shoulders",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kAZOMqrQ--ISvk",
    id: "3697",
    name: "cable kneeling rear delt row (with rope) (male)",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/XOBb3exb0ZgOUz",
    id: "0176",
    name: "cable kneeling triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ydM7aPZ70C2Y-W",
    id: "2330",
    name: "cable lat pulldown full range of motion",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kmkvPLwDr-SyJ6",
    id: "0177",
    name: "cable lateral pulldown (with rope attachment)",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/vI7hf70q6gAtZc",
    id: "2616",
    name: "cable lateral pulldown with v-bar",
    target: "lats",
  },
  {
    bodyPart: "shoulders",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/DOBhMyB8q3uKUj",
    id: "0178",
    name: "cable lateral raise",
    target: "delts",
  },
  {
    bodyPart: "chest",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/FpjBuME2PguH-7",
    id: "0179",
    name: "cable low fly",
    target: "pectorals",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Ol-59Aa9vhhgA5",
    id: "0180",
    name: "cable low seated row",
    target: "upper back",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/DnOBb6ePaMPiiN",
    id: "1634",
    name: "cable lying bicep curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/6RBNf7TjZHZw3m",
    id: "0182",
    name: "cable lying close-grip curl",
    target: "biceps",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/x2ShpGb6UMh9qP",
    id: "0184",
    name: "cable lying extension pullover (with rope attachment)",
    target: "lats",
  },
  {
    bodyPart: "chest",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Beb92CGlUKdbSX",
    id: "0185",
    name: "cable lying fly",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/DC6eIbKASc24RU",
    id: "0186",
    name: "cable lying triceps extension v. 2",
    target: "triceps",
  },
  {
    bodyPart: "chest",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/nMODGheL4h1UrF",
    id: "0188",
    name: "cable middle fly",
    target: "pectorals",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/DBuDP4Rvzxv34-",
    id: "0189",
    name: "cable one arm bent over row",
    target: "upper back",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/XAsZ5TWpvnVGJ3",
    id: "0190",
    name: "cable one arm curl",
    target: "biceps",
  },
  {
    bodyPart: "chest",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/h4axVHCifp5E2P",
    id: "1262",
    name: "cable one arm decline chest fly",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Q5HcWRQjy06NQV",
    id: "1263",
    name: "cable one arm fly on exercise ball",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/g5Ej2YFCAu1BdM",
    id: "1264",
    name: "cable one arm incline fly on exercise ball",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/srfGAs1jr3w-Rs",
    id: "1265",
    name: "cable one arm incline press",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/UR-k8DpEuPMfQQ",
    id: "1266",
    name: "cable one arm incline press on exercise ball",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/aysgXaR3dsvcnC",
    id: "0191",
    name: "cable one arm lateral bent-over",
    target: "pectorals",
  },
  {
    bodyPart: "shoulders",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ZypmaIOSbM-IGM",
    id: "0192",
    name: "cable one arm lateral raise",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Y-exYNTmxbOsoQ",
    id: "1633",
    name: "cable one arm preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "chest",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/a7o6d3jFrwpZ3r",
    id: "1267",
    name: "cable one arm press on exercise ball",
    target: "pectorals",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/RMzHaWa3Z3Tkri",
    id: "3563",
    name: "cable one arm pulldown",
    target: "lats",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/n-zGosOIqYw6Mm",
    id: "1635",
    name: "cable one arm reverse preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/vp5HMQgYl9aWuZ",
    id: "0193",
    name: "cable one arm straight back high row (kneeling)",
    target: "upper back",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/1SBWQ9w2hf7nnq",
    id: "1723",
    name: "cable one arm tricep pushdown",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Ucc4XkbR6kccT2",
    id: "1636",
    name: "cable overhead curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/gLMsu7X17C0z5Z",
    id: "1637",
    name: "cable overhead curl on exercise ball",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/R9MBjY8J2PzLnS",
    id: "0194",
    name: "cable overhead triceps extension (rope attachment)",
    target: "triceps",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/hf7DKIIJBsAiKh",
    id: "1319",
    name: "cable palm rotational row",
    target: "upper back",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/VTJ2uO4wENfFTU",
    id: "0195",
    name: "cable preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "chest",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/IkGqHQ1ltIqeRS",
    id: "1268",
    name: "cable press on exercise ball",
    target: "pectorals",
  },
  {
    bodyPart: "upper legs",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/rWUMwrDe5Cx1MG",
    id: "0196",
    name: "cable pull through (with rope)",
    target: "glutes",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/zHw0C4xrjcTrJw",
    id: "0198",
    name: "cable pulldown",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/P36XrgI6xTT6Tb",
    id: "0197",
    name: "cable pulldown (pro lat bar)",
    target: "lats",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/i7UltOZerJbPoe",
    id: "1638",
    name: "cable pulldown bicep curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/8bw5XIaz9rrGqs",
    id: "0201",
    name: "cable pushdown",
    target: "triceps",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kJUpgj5ePP9nKD",
    id: "0199",
    name: "cable pushdown (straight arm) v. 2",
    target: "lats",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/KXhOWfy8KOdoR2",
    id: "0200",
    name: "cable pushdown (with rope attachment)",
    target: "triceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Re6Hec9KZOWtJr",
    id: "0202",
    name: "cable rear delt row (stirrups)",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/LYy8mIbwma6nsV",
    id: "0203",
    name: "cable rear delt row (with rope)",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/nLZpzJIpsmf7dB",
    id: "0204",
    name: "cable rear drive",
    target: "triceps",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/vTpmags6ZFNknb",
    id: "0205",
    name: "cable rear pulldown",
    target: "lats",
  },
  {
    bodyPart: "waist",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/okmK2-JPIayCNJ",
    id: "0873",
    name: "cable reverse crunch",
    target: "abs",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/OU-G420fLO10QG",
    id: "0206",
    name: "cable reverse curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/XTPMAiy6ScHonw",
    id: "2406",
    name: "cable reverse grip triceps pushdown (sz-bar) (with arm blaster)",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ai34369d28BmG9",
    id: "1413",
    name: "cable reverse one arm curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kk4HxctfIqXFmH",
    id: "0209",
    name: "cable reverse preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "lower arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/1kewSwnV4dRZ1q",
    id: "0210",
    name: "cable reverse wrist curl",
    target: "forearms",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/LVRYD495ufNzBM",
    id: "0207",
    name: "cable reverse-grip pushdown",
    target: "triceps",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/h2H-8CsRQ2u9DQ",
    id: "0208",
    name: "cable reverse-grip straight back seated high row",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/yv22t1V3m1751F",
    id: "1320",
    name: "cable rope crossover seated row",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/9KZpOdFNsKCvDp",
    id: "1321",
    name: "cable rope elevated seated row",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/p4aHGJwhEVvlQz",
    id: "1322",
    name: "cable rope extension incline bench row",
    target: "upper back",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/wjs6a38Oh5e-CH",
    id: "1639",
    name: "cable rope hammer preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/eadWd9G5Fjw6at",
    id: "1724",
    name: "cable rope high pulley overhead tricep extension",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/r6JWKiYM8hMiKq",
    id: "1725",
    name: "cable rope incline tricep extension",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/3ZYTr74kigfmag",
    id: "1726",
    name: "cable rope lying on floor tricep extension",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/uXlf-oux8HRTYt",
    id: "1640",
    name: "cable rope one arm hammer preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/a5wfDDjLnNiqwI",
    id: "1323",
    name: "cable rope seated row",
    target: "upper back",
  },
  {
    bodyPart: "waist",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/J4ty-b7yLU8ePL",
    id: "0211",
    name: "cable russian twists (on stability ball)",
    target: "abs",
  },
  {
    bodyPart: "chest",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/odAhGQQKmsE93r",
    id: "2144",
    name: "cable seated chest press",
    target: "pectorals",
  },
  {
    bodyPart: "waist",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/4rB3EQC0rhqgUt",
    id: "0212",
    name: "cable seated crunch",
    target: "abs",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/hwj3xbbZPnbNnK",
    id: "1641",
    name: "cable seated curl",
    target: "biceps",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/OFRiw6DqS3EDtg",
    id: "0213",
    name: "cable seated high row (v-bar)",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/VmIrE3Lqld9P7w",
    id: "0214",
    name: "cable seated one arm alternate row",
    target: "upper back",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/6xhHe1R7nyyjJF",
    id: "1642",
    name: "cable seated one arm concentration curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/1BD8IUcvu2Zk3J",
    id: "1643",
    name: "cable seated overhead curl",
    target: "biceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/mcs7Ht4PVlvvUW",
    id: "0215",
    name: "cable seated rear lateral raise",
    target: "delts",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Lrp6UEJFtKWxRA",
    id: "0861",
    name: "cable seated row",
    target: "upper back",
  },
  {
    bodyPart: "shoulders",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/1yT7xmlTllq33I",
    id: "0216",
    name: "cable seated shoulder internal rotation",
    target: "delts",
  },
  {
    bodyPart: "waist",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/vm8W4NdDEPNp-8",
    id: "2399",
    name: "cable seated twist",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/BA7aqTT8RsyHg3",
    id: "0218",
    name: "cable seated wide-grip row",
    target: "upper back",
  },
  {
    bodyPart: "shoulders",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/-1wpa9KrNRDo5d",
    id: "0219",
    name: "cable shoulder press",
    target: "delts",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/h66dq3vpjsFiIr",
    id: "0220",
    name: "cable shrug",
    target: "traps",
  },
  {
    bodyPart: "waist",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/K6o7QXFJWE0Bck",
    id: "0222",
    name: "cable side bend",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/-DACML8cGsd6RC",
    id: "0221",
    name: "cable side bend crunch (bosu ball)",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/agfGCE1609KJYj",
    id: "0223",
    name: "cable side crunch",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/b0cYHmU6x7Z6RV",
    id: "1717",
    name: "cable squat row (with rope attachment)",
    target: "lats",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/oEkt6XHHvuOKBB",
    id: "1644",
    name: "cable squatting curl",
    target: "biceps",
  },
  {
    bodyPart: "lower arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qTEJxi21CDx6Qr",
    id: "0224",
    name: "cable standing back wrist curl",
    target: "forearms",
  },
  {
    bodyPart: "lower legs",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/D0g-NW9ICZdC0c",
    id: "1375",
    name: "cable standing calf raise",
    target: "calves",
  },
  {
    bodyPart: "shoulders",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ZS2oFtbjHhgLUz",
    id: "0225",
    name: "cable standing cross-over high reverse fly",
    target: "delts",
  },
  {
    bodyPart: "waist",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/MkgtQeZ4fko3Lx",
    id: "0226",
    name: "cable standing crunch",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ybJO9FYiSdLhbs",
    id: "0874",
    name: "cable standing crunch (with rope attachment)",
    target: "abs",
  },
  {
    bodyPart: "chest",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/bbQc9LB94gQDUk",
    id: "0227",
    name: "cable standing fly",
    target: "pectorals",
  },
  {
    bodyPart: "upper legs",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/XID90JA0bzrB15",
    id: "0228",
    name: "cable standing hip extension",
    target: "glutes",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/yGukEqI-jRPdFF",
    id: "0229",
    name: "cable standing inner curl",
    target: "biceps",
  },
  {
    bodyPart: "waist",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ouUzvzb7ukAvZV",
    id: "0230",
    name: "cable standing lift",
    target: "abs",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/XzDwPygipWj4KV",
    id: "0231",
    name: "cable standing one arm triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "lower legs",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/nKXCNIv2CpRegY",
    id: "1376",
    name: "cable standing one leg calf raise",
    target: "calves",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/yeYfFZkaNhUyMe",
    id: "0232",
    name: "cable standing pulldown (with rope)",
    target: "biceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/bIP4CoZ4jhJAJ1",
    id: "0233",
    name: "cable standing rear delt row (with rope)",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/bulq5Q-lYMVnUN",
    id: "1727",
    name: "cable standing reverse grip one arm overhead tricep extension",
    target: "triceps",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/cIFtpHErhGK7br",
    id: "0234",
    name: "cable standing row (v-bar)",
    target: "upper back",
  },
  {
    bodyPart: "shoulders",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kwmoHl4e2DaMbe",
    id: "0235",
    name: "cable standing shoulder external rotation",
    target: "delts",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/XKVlkpEavj-JQg",
    id: "0236",
    name: "cable standing twist row (v-bar)",
    target: "upper back",
  },
  {
    bodyPart: "chest",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Cp66OtjN6WZy6L",
    id: "1269",
    name: "cable standing up straight crossovers",
    target: "pectorals",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/uIWzOUFWsQah8C",
    id: "0238",
    name: "cable straight arm pulldown",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/BVrYH2OutouNoC",
    id: "0237",
    name: "cable straight arm pulldown (with rope)",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/lnXuPBaNHwKmRd",
    id: "0239",
    name: "cable straight back seated row",
    target: "upper back",
  },
  {
    bodyPart: "shoulders",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/tRHz591YRHbwKN",
    id: "0240",
    name: "cable supine reverse fly",
    target: "delts",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/vVEeGDUdIMQFT2",
    id: "2464",
    name: "cable thibaudeau kayak row",
    target: "lats",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/vnSPv-7e3FClHP",
    id: "0241",
    name: "cable triceps pushdown (v-bar)",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/6ymN5YIlHRNYfO",
    id: "2405",
    name: "cable triceps pushdown (v-bar) (with arm blaster)",
    target: "triceps",
  },
  {
    bodyPart: "waist",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/xeXWR-dMFX7XwE",
    id: "0242",
    name: "cable tuck reverse crunch",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/aWKEvdU1e5kiFK",
    id: "0243",
    name: "cable twist",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/aoABr3tel9m0Ku",
    id: "0862",
    name: "cable twist (up-down)",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/q9TNYl66zeuSyX",
    id: "0244",
    name: "cable twisting pull",
    target: "lats",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/QVQ6wD4190Q1F2",
    id: "1645",
    name: "cable two arm curl on incline bench",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/iiR1sfjnzv164p",
    id: "1728",
    name: "cable two arm tricep kickback",
    target: "triceps",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/beJpGyqmttwg9L",
    id: "0245",
    name: "cable underhand pulldown",
    target: "lats",
  },
  {
    bodyPart: "chest",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/DSm8boz5CoSkI3",
    id: "1270",
    name: "cable upper chest crossovers",
    target: "pectorals",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/aYZ1pdX1dqwiKa",
    id: "1324",
    name: "cable upper row",
    target: "upper back",
  },
  {
    bodyPart: "shoulders",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/0hST07SycL1HE3",
    id: "0246",
    name: "cable upright row",
    target: "delts",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/TKhare-KwFz8rQ",
    id: "1325",
    name: "cable wide grip rear pulldown behind neck",
    target: "lats",
  },
  {
    bodyPart: "lower arms",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/52sZeeF2IGSeVM",
    id: "0247",
    name: "cable wrist curl",
    target: "forearms",
  },
  {
    bodyPart: "lower legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/CKf0kMKDW29soE",
    id: "1407",
    name: "calf push stretch with hands against wall",
    target: "calves",
  },
  {
    bodyPart: "lower legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/6uvMSLWFoQcxrE",
    id: "1377",
    name: "calf stretch with hands against wall",
    target: "calves",
  },
  {
    bodyPart: "lower legs",
    equipment: "rope",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/hIuoirh0ok-o9z",
    id: "1378",
    name: "calf stretch with rope",
    target: "calves",
  },
  {
    bodyPart: "back",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/0VVJGQu8fsHdUh",
    id: "0248",
    name: "cambered bar lying row",
    target: "upper back",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/R08rLSZABWfCvF",
    id: "2963",
    name: "captains chair straight leg raise",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/vzrDmYR-A3XjKi",
    id: "1548",
    name: "chair leg extended stretch",
    target: "quads",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ENZoMUszBiGsxK",
    id: "1271",
    name: "chest and front of shoulder stretch",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/L7jQ2pGBl8axx5",
    id: "0251",
    name: "chest dip",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/h3jLZFdgPGVA1e",
    id: "1430",
    name: "chest dip (on dip-pull-up cage)",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/c2xwTkpLXV9MnJ",
    id: "2462",
    name: "chest dip on straight bar",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/88laAkCTm67r3t",
    id: "1272",
    name: "chest stretch with exercise ball",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/BHMTDUyfBdQf9g",
    id: "3216",
    name: "chest tap push-up (male)",
    target: "pectorals",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/mCZ0KNZ8acg9pI",
    id: "1326",
    name: "chin-up",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/FVDg3X7AWap7If",
    id: "0253",
    name: "chin-ups (narrow parallel grip)",
    target: "upper back",
  },
  {
    bodyPart: "lower legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/50cB7lJMAJaMa0",
    id: "0257",
    name: "circles knee stretch",
    target: "calves",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/jw1oBeFRHM35TK",
    id: "1273",
    name: "clap push up",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/LALfNxhqODdwvr",
    id: "0258",
    name: "clock push-up",
    target: "pectorals",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/SbKiQmei3WBsfU",
    id: "1327",
    name: "close grip chin-up",
    target: "lats",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ccD4ukfgFI8fb3",
    id: "0259",
    name: "close-grip push-up",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/oHOUDBMzpTuKFa",
    id: "2398",
    name: "close-grip push-up (on knees)",
    target: "triceps",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Oc5wt3u3qR7kuc",
    id: "0260",
    name: "cocoons",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qzUkR1MW3e07cD",
    id: "1468",
    name: "crab twist toe touch",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/cSrv9Owy8qFiw0",
    id: "0262",
    name: "cross body crunch",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Azs2a61DBE7Bm1",
    id: "0267",
    name: "crunch (hands overhead)",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/NITWI7eT7wcXYS",
    id: "0271",
    name: "crunch (on stability ball)",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/4RHPYkKhDHe8wM",
    id: "0272",
    name: "crunch (on stability ball, arms straight)",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/EsDNFuno3Lre-t",
    id: "0274",
    name: "crunch floor",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/bb5AA99-16KqeZ",
    id: "3016",
    name: "curl-up",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qusVOD6DxRyduI",
    id: "3769",
    name: "curtsey squat",
    target: "glutes",
  },
  {
    bodyPart: "cardio",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/1t5H94HkxEXKDE",
    id: "2331",
    name: "cycle cross trainer",
    target: "cardiovascular system",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/RpswhLALmtj2QB",
    id: "0276",
    name: "dead bug",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ci6C97vS9Hjygo",
    id: "0277",
    name: "decline crunch",
    target: "abs",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/v1lpiJJhJTF80z",
    id: "0279",
    name: "decline push-up",
    target: "pectorals",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/398Kw8zXoxJZGC",
    id: "0282",
    name: "decline sit-up",
    target: "abs",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/jgzYOYiGuIY9H2",
    id: "1274",
    name: "deep push up",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/faX22Mw6yz044g",
    id: "0283",
    name: "diamond push-up",
    target: "triceps",
  },
  {
    bodyPart: "lower legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/lCzdhsA4IVHXPI",
    id: "0284",
    name: "donkey calf raise",
    target: "calves",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/MQBExxWtWi-d6V",
    id: "1275",
    name: "drop push up",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/bq72MsJzZlkssi",
    id: "0285",
    name: "dumbbell alternate biceps curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/LqnYVsdhnM7rKd",
    id: "2403",
    name: "dumbbell alternate biceps curl (with arm blaster)",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/MctOAlf0myAw6k",
    id: "1646",
    name: "dumbbell alternate hammer preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/hnYhlOMwSlnCUf",
    id: "1647",
    name: "dumbbell alternate preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/MQKSSeDFCuK5Fn",
    id: "1648",
    name: "dumbbell alternate seated hammer curl",
    target: "biceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/lnHL6L502kcK3F",
    id: "0286",
    name: "dumbbell alternate side press",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/CiqWKQRNuBi7Ws",
    id: "1649",
    name: "dumbbell alternating bicep curl with leg raised on exercise ball",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/9iNBoTBJBhcqlX",
    id: "1650",
    name: "dumbbell alternating seated bicep curl on exercise ball",
    target: "biceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/cGgffiCkUoBjjl",
    id: "2137",
    name: "dumbbell arnold press",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/eSBsJecdJW8IEK",
    id: "0287",
    name: "dumbbell arnold press v. 2",
    target: "delts",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/emvcs0JdoXHzV8",
    id: "0288",
    name: "dumbbell around pullover",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Kw2HtxzuwVEt5s",
    id: "0289",
    name: "dumbbell bench press",
    target: "pectorals",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/YUk4g5QDbwPtTR",
    id: "0290",
    name: "dumbbell bench seated press",
    target: "delts",
  },
  {
    bodyPart: "upper legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/aWrWXffjdKZsNx",
    id: "0291",
    name: "dumbbell bench squat",
    target: "glutes",
  },
  {
    bodyPart: "back",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/MLhyGBZ7HhrnKR",
    id: "0293",
    name: "dumbbell bent over row",
    target: "upper back",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/mEiUwVac7sdMjx",
    id: "1651",
    name: "dumbbell bicep curl lunge with bowling motion",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/4jbQsGxg2jyJtS",
    id: "1652",
    name: "dumbbell bicep curl on exercise ball with leg raised",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/gRUA4qfpJs55p9",
    id: "1653",
    name: "dumbbell bicep curl with stork stance",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/PLp89BZ-HVo8sf",
    id: "0294",
    name: "dumbbell biceps curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/e-17DADLRNSxRH",
    id: "2401",
    name: "dumbbell biceps curl (with arm blaster)",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/AQpyFWy7G0aVAn",
    id: "1654",
    name: "dumbbell biceps curl reverse",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/3NXvd1OXIp694g",
    id: "1655",
    name: "dumbbell biceps curl squat",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/PGPU-LsSt9Tbm9",
    id: "1656",
    name: "dumbbell biceps curl v sit on bosu ball",
    target: "biceps",
  },
  {
    bodyPart: "cardio",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/h2pMUBcUlzw6dm",
    id: "1201",
    name: "dumbbell burpee",
    target: "cardiovascular system",
  },
  {
    bodyPart: "upper legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/trYiV2OGA2C5rE",
    id: "0295",
    name: "dumbbell clean",
    target: "glutes",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/l-nAEzsTbTYuLR",
    id: "1731",
    name: "dumbbell close grip press",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/iIWJg881FKycGx",
    id: "0296",
    name: "dumbbell close-grip press",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/cWVreBfkjGy6Ho",
    id: "0297",
    name: "dumbbell concentration curl",
    target: "biceps",
  },
  {
    bodyPart: "upper legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/L2v6-EgwE95C7g",
    id: "3635",
    name: "dumbbell contralateral forward lunge",
    target: "glutes",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/MtRD5iLpQni8LV",
    id: "0298",
    name: "dumbbell cross body hammer curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/4f59DuwUM8mJM7",
    id: "1657",
    name: "dumbbell cross body hammer curl v. 2",
    target: "biceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/JQtlzN4eKIr6Xa",
    id: "0299",
    name: "dumbbell cuban press",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/6ekMmXoz4kq2zY",
    id: "2136",
    name: "dumbbell cuban press v. 2",
    target: "delts",
  },
  {
    bodyPart: "upper legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/9vKDwb-q8GeUhI",
    id: "0300",
    name: "dumbbell deadlift",
    target: "glutes",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/yISZNlXWHU-13O",
    id: "0301",
    name: "dumbbell decline bench press",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/9nXDmk7a0eT8NE",
    id: "0302",
    name: "dumbbell decline fly",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/FrclkttDtmChp3",
    id: "0303",
    name: "dumbbell decline hammer press",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/vQ4GQgohwToeqY",
    id: "1276",
    name: "dumbbell decline one arm fly",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/--bMFUASyTXOJH",
    id: "1617",
    name: "dumbbell decline one arm hammer press",
    target: "triceps",
  },
  {
    bodyPart: "back",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/wIjmwzPJpNnD27",
    id: "0305",
    name: "dumbbell decline shrug",
    target: "traps",
  },
  {
    bodyPart: "back",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/wuYX0ZRi7Nokzd",
    id: "0304",
    name: "dumbbell decline shrug v. 2",
    target: "traps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/WvU-7nnCu1gc1m",
    id: "0306",
    name: "dumbbell decline triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/1WygVyPHwe7RBH",
    id: "0307",
    name: "dumbbell decline twist fly",
    target: "pectorals",
  },
  {
    bodyPart: "lower arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/0kgHZBlMvJ1BFQ",
    id: "1437",
    name: "dumbbell finger curls",
    target: "forearms",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/MRIOsiK-MmSXpG",
    id: "0308",
    name: "dumbbell fly",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/C3ZpKfl6uOK2ye",
    id: "1277",
    name: "dumbbell fly on exercise ball",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ircf0O5An6fqY6",
    id: "1732",
    name: "dumbbell forward lunge triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/o2K0nonerKbEVy",
    id: "0310",
    name: "dumbbell front raise",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/8n9vErp6pq8JlW",
    id: "0309",
    name: "dumbbell front raise v. 2",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/hO9-z1Ri-rkpF9",
    id: "0311",
    name: "dumbbell full can lateral raise",
    target: "delts",
  },
  {
    bodyPart: "upper legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/n2q9xNFyFNmB22",
    id: "1760",
    name: "dumbbell goblet squat",
    target: "quads",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/J260IlX3BKc1-Q",
    id: "0313",
    name: "dumbbell hammer curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/HcCjQZz8O5062h",
    id: "1659",
    name: "dumbbell hammer curl on exercise ball",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/VRvSPvl1hcimzB",
    id: "0312",
    name: "dumbbell hammer curl v. 2",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kkmuMO4ZXOxsoU",
    id: "2402",
    name: "dumbbell hammer curls (with arm blaster)",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/fcR1hGdnv7xa4c",
    id: "1664",
    name: "dumbbell high curl",
    target: "biceps",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/jOmKchDz2Bok55",
    id: "3545",
    name: "dumbbell incline alternate press",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/AZfMQM5rXOBPmj",
    id: "0314",
    name: "dumbbell incline bench press",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/VCG4niLNRwfhA4",
    id: "0315",
    name: "dumbbell incline biceps curl",
    target: "biceps",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/dju6BBK-iHnRUW",
    id: "0316",
    name: "dumbbell incline breeding",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qrdX0YHqu9Okl5",
    id: "0318",
    name: "dumbbell incline curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Hrb2gTQ5cEs5C4",
    id: "0317",
    name: "dumbbell incline curl v. 2",
    target: "biceps",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/jqk0Iuphe88TuE",
    id: "0319",
    name: "dumbbell incline fly",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/oNd8O-fH4aIOQV",
    id: "1278",
    name: "dumbbell incline fly on exercise ball",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/eYEIcK-tEIAtbR",
    id: "0320",
    name: "dumbbell incline hammer curl",
    target: "biceps",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/etBdXx8j7vQGSm",
    id: "0321",
    name: "dumbbell incline hammer press",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/wLz30a65xlIsZR",
    id: "1618",
    name: "dumbbell incline hammer press on exercise ball",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ZkvDNJxEzj6nXC",
    id: "0322",
    name: "dumbbell incline inner biceps curl",
    target: "biceps",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/I-HNnQWlLjDCOT",
    id: "1279",
    name: "dumbbell incline one arm fly",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/wdwIupD0cx24wi",
    id: "1280",
    name: "dumbbell incline one arm fly on exercise ball",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/uYqSc1oEG9eYod",
    id: "1619",
    name: "dumbbell incline one arm hammer press",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/GBppMloePtO7-g",
    id: "1620",
    name: "dumbbell incline one arm hammer press on exercise ball",
    target: "triceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/XsaJMHppXh0fqa",
    id: "0323",
    name: "dumbbell incline one arm lateral raise",
    target: "delts",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/LqpLcxpaC4lKtK",
    id: "1281",
    name: "dumbbell incline one arm press",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/rT5Cnld5I-TQpZ",
    id: "1282",
    name: "dumbbell incline one arm press on exercise ball",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/-Ui9jOSKk5ZnoY",
    id: "0324",
    name: "dumbbell incline palm-in press",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/iiG89-YjcGbYZn",
    id: "1283",
    name: "dumbbell incline press on exercise ball",
    target: "pectorals",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/cpuptwGmcTdkdW",
    id: "0325",
    name: "dumbbell incline raise",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/2U5-wEs0li9a3O",
    id: "0326",
    name: "dumbbell incline rear lateral raise",
    target: "delts",
  },
  {
    bodyPart: "back",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Se9CtbJRTJNtxd",
    id: "0327",
    name: "dumbbell incline row",
    target: "upper back",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/IAmb02bKFUeFPF",
    id: "0328",
    name: "dumbbell incline shoulder raise",
    target: "serratus anterior",
  },
  {
    bodyPart: "back",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/yU0mD8rsCYHSLU",
    id: "0329",
    name: "dumbbell incline shrug",
    target: "traps",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/SFLc-Jd73Ikwje",
    id: "3542",
    name: "dumbbell incline t-raise",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Sc9U0SIlYqHMLK",
    id: "0330",
    name: "dumbbell incline triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qVCCGNCIk6AHcG",
    id: "0331",
    name: "dumbbell incline twisted flyes",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/YgyESj4ELK81Is",
    id: "1733",
    name: "dumbbell incline two arm extension",
    target: "triceps",
  },
  {
    bodyPart: "back",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ccp1ElaRNOIJeb",
    id: "3541",
    name: "dumbbell incline y-raise",
    target: "upper back",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/PlRSClvmcoJ-eh",
    id: "0332",
    name: "dumbbell iron cross",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kR0yoWUGy8r1bR",
    id: "0333",
    name: "dumbbell kickback",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/blQRXDpFrOmidg",
    id: "1734",
    name: "dumbbell kickbacks on exercise ball",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/yBaVl2-QCVpHY-",
    id: "1660",
    name: "dumbbell kneeling bicep curl exercise ball",
    target: "biceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/OnIpDabYL5MFiQ",
    id: "0334",
    name: "dumbbell lateral raise",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/72Ml03njmoRdo9",
    id: "0335",
    name: "dumbbell lateral to front raise",
    target: "delts",
  },
  {
    bodyPart: "upper legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/4UrpE80Mqi9v9-",
    id: "0336",
    name: "dumbbell lunge",
    target: "glutes",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/XTa6SyFzjC9Mkc",
    id: "1658",
    name: "dumbbell lunge with bicep curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/6J-39MkANVaxra",
    id: "0337",
    name: "dumbbell lying  extension (across face)",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/8hZIJ2i6dD8aSZ",
    id: "1729",
    name: "dumbbell lying alternate extension",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/mGist8G3W4o6Nc",
    id: "0338",
    name: "dumbbell lying elbow press",
    target: "triceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/fcWfCD8E2C6iZS",
    id: "0863",
    name: "dumbbell lying external shoulder rotation",
    target: "delts",
  },
  {
    bodyPart: "upper legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/cjqGbq74Kv9ljH",
    id: "0339",
    name: "dumbbell lying femoral",
    target: "hamstrings",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Hoz5dfgGoeNJGP",
    id: "0340",
    name: "dumbbell lying hammer press",
    target: "pectorals",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/JpYyRSMCSPvZ9B",
    id: "2470",
    name: "dumbbell lying on floor rear delt raise",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/zIgA6braLPxrPi",
    id: "0341",
    name: "dumbbell lying one arm deltoid rear",
    target: "delts",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/EPq8cRIWUylo6B",
    id: "0343",
    name: "dumbbell lying one arm press",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/pcjcocau4iOzAD",
    id: "0342",
    name: "dumbbell lying one arm press v. 2",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Oja5vh3j0m7zpP",
    id: "0344",
    name: "dumbbell lying one arm pronated triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/cSXzxQ4FfvmUJ6",
    id: "0345",
    name: "dumbbell lying one arm rear lateral raise",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/-gGwMHKkwjH6Ne",
    id: "0346",
    name: "dumbbell lying one arm supinated triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "lower arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/aQbQ1R5N6eSDA3",
    id: "0347",
    name: "dumbbell lying pronation",
    target: "forearms",
  },
  {
    bodyPart: "lower arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/EzCK9kVX0sfGmR",
    id: "2705",
    name: "dumbbell lying pronation on floor",
    target: "forearms",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/HXHVXNzFHfJ9Nw",
    id: "1284",
    name: "dumbbell lying pullover on exercise ball",
    target: "pectorals",
  },
  {
    bodyPart: "back",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/iHaPRpJbvYefP8",
    id: "1328",
    name: "dumbbell lying rear delt row",
    target: "upper back",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/erJTdw8ZyUBaYW",
    id: "0348",
    name: "dumbbell lying rear lateral raise",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/v0lWvHbyfU50S-",
    id: "1735",
    name: "dumbbell lying single extension",
    target: "triceps",
  },
  {
    bodyPart: "lower arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/pP04TXc24CwZ2U",
    id: "0349",
    name: "dumbbell lying supination",
    target: "forearms",
  },
  {
    bodyPart: "lower arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/BlsbWixEcS-xVq",
    id: "2706",
    name: "dumbbell lying supination on floor",
    target: "forearms",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/8ZUPOBcvj6HG5D",
    id: "1661",
    name: "dumbbell lying supine biceps curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Mn62ri-UQ7Z6jt",
    id: "0350",
    name: "dumbbell lying supine curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ydalmawk68T9gR",
    id: "0351",
    name: "dumbbell lying triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ugn78eB-SHuui0",
    id: "1662",
    name: "dumbbell lying wide curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/LCLVlMt9iF2cEx",
    id: "0352",
    name: "dumbbell neutral grip bench press",
    target: "triceps",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Ar6RZZbTpvfE7L",
    id: "1285",
    name: "dumbbell one arm bench fly",
    target: "pectorals",
  },
  {
    bodyPart: "back",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/CPHldaLYb6DLO2",
    id: "0292",
    name: "dumbbell one arm bent-over row",
    target: "upper back",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/AIgVUXmQCWHfXe",
    id: "1286",
    name: "dumbbell one arm chest fly on exercise ball",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/dt-N4zrXhy2HnZ",
    id: "0353",
    name: "dumbbell one arm concetration curl (on stability ball)",
    target: "biceps",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/VKckfgqD2P08-C",
    id: "1287",
    name: "dumbbell one arm decline chest press",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/EePmbJEhzHAFeY",
    id: "1288",
    name: "dumbbell one arm fly on exercise ball",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/xw3toJiLJQapZm",
    id: "1736",
    name: "dumbbell one arm french press on exercise ball",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ObUCH-Chh31pBL",
    id: "1663",
    name: "dumbbell one arm hammer preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/07xnymMAOZEba9",
    id: "1621",
    name: "dumbbell one arm hammer press on exercise ball",
    target: "triceps",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/rSBRg-ZYcgnm5u",
    id: "1289",
    name: "dumbbell one arm incline chest press",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/xD0SPaZ15vZomU",
    id: "0354",
    name: "dumbbell one arm kickback",
    target: "triceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/DUmFQH1tI60OGQ",
    id: "0355",
    name: "dumbbell one arm lateral raise",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/g6t3-JJdJ9wJ5U",
    id: "0356",
    name: "dumbbell one arm lateral raise with support",
    target: "delts",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/-dSpX491YE8H1N",
    id: "1290",
    name: "dumbbell one arm press on exercise ball",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Lz77f77FzNLJsP",
    id: "1665",
    name: "dumbbell one arm prone curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/D88Qe6WuinOLLM",
    id: "1666",
    name: "dumbbell one arm prone hammer curl",
    target: "biceps",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/r9PQ34pVlKJRBz",
    id: "1291",
    name: "dumbbell one arm pullover on exercise ball",
    target: "pectorals",
  },
  {
    bodyPart: "lower arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/-5cFGhacAY8d3U",
    id: "0358",
    name: "dumbbell one arm revers wrist curl",
    target: "forearms",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ZCFCoOdkGGZJjt",
    id: "0359",
    name: "dumbbell one arm reverse fly (with support)",
    target: "delts",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qUJHEhM0KWdjD-",
    id: "1622",
    name: "dumbbell one arm reverse grip press",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/fW6XkDg4Egpp6P",
    id: "1414",
    name: "dumbbell one arm reverse preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/tEGF9hksJOef42",
    id: "1667",
    name: "dumbbell one arm reverse spider curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/k8Oy51jBxgwH1f",
    id: "1668",
    name: "dumbbell one arm seated bicep curl on exercise ball",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/HC-B8ijNKFyM1F",
    id: "1669",
    name: "dumbbell one arm seated hammer curl",
    target: "biceps",
  },
  {
    bodyPart: "lower arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/YY2GT6jYIfZeJ9",
    id: "1415",
    name: "dumbbell one arm seated neutral wrist curl",
    target: "forearms",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/wi9Z-SQXdG-rbe",
    id: "0361",
    name: "dumbbell one arm shoulder press",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/uqmK7GMg694Mx4",
    id: "0360",
    name: "dumbbell one arm shoulder press v. 2",
    target: "delts",
  },
  {
    bodyPart: "upper legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/xEITcZ4jLDnI9t",
    id: "3888",
    name: "dumbbell one arm snatch",
    target: "glutes",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/34t4OwMv0tb7x2",
    id: "1670",
    name: "dumbbell one arm standing curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/V3WdaIY9tNAfTa",
    id: "1671",
    name: "dumbbell one arm standing hammer curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/dNQ8l9AWij9sh9",
    id: "0362",
    name: "dumbbell one arm triceps extension (on bench)",
    target: "triceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/bu1ZBF5cmPO1WQ",
    id: "0363",
    name: "dumbbell one arm upright row",
    target: "delts",
  },
  {
    bodyPart: "lower arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/OUue2l1juTf6oU",
    id: "0364",
    name: "dumbbell one arm wrist curl",
    target: "forearms",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/fKJhsYY7lkGzjZ",
    id: "1672",
    name: "dumbbell one arm zottman preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/IhdDw-jH1miqib",
    id: "1292",
    name: "dumbbell one leg fly on exercise ball",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/QSTQlmBOW54QvC",
    id: "0365",
    name: "dumbbell over bench neutral wrist curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/8BUn4b-fERlVnA",
    id: "0366",
    name: "dumbbell over bench one arm  neutral wrist curl",
    target: "biceps",
  },
  {
    bodyPart: "lower arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/OwPwpwygB3JZ3Z",
    id: "1441",
    name: "dumbbell over bench one arm reverse wrist curl",
    target: "forearms",
  },
  {
    bodyPart: "lower arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/2qj95zeFwisSZX",
    id: "0367",
    name: "dumbbell over bench one arm wrist curl",
    target: "forearms",
  },
  {
    bodyPart: "lower arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/sEXovhLX8rfqTH",
    id: "0368",
    name: "dumbbell over bench revers wrist curl",
    target: "forearms",
  },
  {
    bodyPart: "lower arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/eW0cdqGcmvv2-T",
    id: "0369",
    name: "dumbbell over bench wrist curl",
    target: "forearms",
  },
  {
    bodyPart: "back",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/MEhprQso2d7PGX",
    id: "1329",
    name: "dumbbell palm rotational bent over row",
    target: "upper back",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/oTX99UyRpBNYGB",
    id: "1623",
    name: "dumbbell palms in incline bench press",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ntC0t4cv4wiHra",
    id: "0370",
    name: "dumbbell peacher hammer curl",
    target: "biceps",
  },
  {
    bodyPart: "upper legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qbLYJ3Nd3BHmCR",
    id: "0371",
    name: "dumbbell plyo squat",
    target: "glutes",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/yHFY4pUVfXSd3r",
    id: "0372",
    name: "dumbbell preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/jYJlsOROR-8Luo",
    id: "1673",
    name: "dumbbell preacher curl over exercise ball",
    target: "biceps",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/-KEbz3-lreGbNC",
    id: "1293",
    name: "dumbbell press on exercise ball",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/IBGjph2N0kZlws",
    id: "0373",
    name: "dumbbell pronate-grip triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/1nctZUfJYoamt3",
    id: "0374",
    name: "dumbbell prone incline curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/0JMktcQngzMOuY",
    id: "1674",
    name: "dumbbell prone incline hammer curl",
    target: "biceps",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/mfquC9rF6kzMTN",
    id: "0375",
    name: "dumbbell pullover",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/aVUiCIrA8ElQgY",
    id: "1294",
    name: "dumbbell pullover hip extension on exercise ball",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/OGiWRvztGlzeKL",
    id: "1295",
    name: "dumbbell pullover on exercise ball",
    target: "pectorals",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/SGNU2JahcMajDO",
    id: "1700",
    name: "dumbbell push press",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ShzUXoWrTdpex9",
    id: "0376",
    name: "dumbbell raise",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/xGqoxjoCBAGCV2",
    id: "2292",
    name: "dumbbell rear delt raise",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/VPxLX746ru1bsL",
    id: "0377",
    name: "dumbbell rear delt row_shoulder",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/CFnf4hK8tPE8IG",
    id: "0378",
    name: "dumbbell rear fly",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/aq9GoSTwshC76R",
    id: "0380",
    name: "dumbbell rear lateral raise",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/oYQuL69hBn61N1",
    id: "0379",
    name: "dumbbell rear lateral raise (support head)",
    target: "delts",
  },
  {
    bodyPart: "upper legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/7nNMKcxm5u7hHP",
    id: "0381",
    name: "dumbbell rear lunge",
    target: "glutes",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/UlbuPbJs2i9n88",
    id: "0382",
    name: "dumbbell revers grip biceps curl",
    target: "biceps",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/BiJEjj3trIQB2R",
    id: "1624",
    name: "dumbbell reverse bench press",
    target: "pectorals",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/BZpNLn98lW90OA",
    id: "0383",
    name: "dumbbell reverse fly",
    target: "delts",
  },
  {
    bodyPart: "back",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/R81Oe-2WYMROzA",
    id: "1330",
    name: "dumbbell reverse grip incline bench one arm row",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/UXLbixLsYf9bpr",
    id: "1331",
    name: "dumbbell reverse grip incline bench two arm row",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/wxekgbsraTET4j",
    id: "2327",
    name: "dumbbell reverse grip row (female)",
    target: "upper back",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/nORYX5UKPlhB9e",
    id: "0384",
    name: "dumbbell reverse preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/rg0H3RU63aklfS",
    id: "1675",
    name: "dumbbell reverse spider curl",
    target: "biceps",
  },
  {
    bodyPart: "lower arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/dFewBk8ujjetdC",
    id: "0385",
    name: "dumbbell reverse wrist curl",
    target: "forearms",
  },
  {
    bodyPart: "upper legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Y9nmrC0YZjc3B5",
    id: "1459",
    name: "dumbbell romanian deadlift",
    target: "glutes",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/R4-OzJGSMdJB1r",
    id: "0386",
    name: "dumbbell rotation reverse fly",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/VNLU885Lbj1DmH",
    id: "2397",
    name: "dumbbell scott press",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/9UMJ1HqzQj6h0B",
    id: "0387",
    name: "dumbbell seated alternate front raise",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/hx8L5Ygd7Bniec",
    id: "1676",
    name: "dumbbell seated alternate hammer curl on exercise ball",
    target: "biceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/waxHoTD0OCaq9I",
    id: "0388",
    name: "dumbbell seated alternate press",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/BhSLkFwscO1EBt",
    id: "3546",
    name: "dumbbell seated alternate shoulder",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/w70xAGU2gLq0dA",
    id: "0389",
    name: "dumbbell seated bench extension",
    target: "triceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kJQbJmwDZLUiAe",
    id: "2317",
    name: "dumbbell seated bent arm lateral raise",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/jjFoUIlA8tBCEO",
    id: "1730",
    name: "dumbbell seated bent over alternate kickback",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/vsUCLt0ufSk9rK",
    id: "1737",
    name: "dumbbell seated bent over triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Y5xzaaGw3-gDoW",
    id: "1677",
    name: "dumbbell seated bicep curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/8cHJXTn-o2NKYE",
    id: "0390",
    name: "dumbbell seated biceps curl (on stability ball)",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/3t7IDIfaUM-dIx",
    id: "3547",
    name: "dumbbell seated biceps curl to shoulder press",
    target: "biceps",
  },
  {
    bodyPart: "lower legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/2jIEfgxQ21g0ZU",
    id: "1379",
    name: "dumbbell seated calf raise",
    target: "calves",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kCsGbaUcISTc-O",
    id: "0391",
    name: "dumbbell seated curl",
    target: "biceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kOx-oegB4Woy6v",
    id: "0392",
    name: "dumbbell seated front raise",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/TXmclyeXEvrTVk",
    id: "1678",
    name: "dumbbell seated hammer curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/muTQWX3v33IkcC",
    id: "0393",
    name: "dumbbell seated inner biceps curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/P8ypwZOMIyYKy9",
    id: "0394",
    name: "dumbbell seated kickback",
    target: "triceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/u-pi1Ll2xd8XcH",
    id: "0396",
    name: "dumbbell seated lateral raise",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/hd1QeQFOTC-Fzn",
    id: "0395",
    name: "dumbbell seated lateral raise v. 2",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/NMSREdqIq8fbil",
    id: "0397",
    name: "dumbbell seated neutral wrist curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kiu7EfFcMO03Rb",
    id: "1679",
    name: "dumbbell seated one arm bicep curl on exercise ball with leg raised",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/apFJFZmrABoBIt",
    id: "0398",
    name: "dumbbell seated one arm kickback",
    target: "triceps",
  },
  {
    bodyPart: "lower arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/nAxSv5faijCVRj",
    id: "0399",
    name: "dumbbell seated one arm rotate",
    target: "forearms",
  },
  {
    bodyPart: "lower legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/3N9QcBqI9Jrspb",
    id: "0400",
    name: "dumbbell seated one leg calf raise",
    target: "calves",
  },
  {
    bodyPart: "lower legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/p6JDKso8Xw-QL6",
    id: "1380",
    name: "dumbbell seated one leg calf raise - hammer grip",
    target: "calves",
  },
  {
    bodyPart: "lower legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/zuwibb27Ppg0Vk",
    id: "1381",
    name: "dumbbell seated one leg calf raise - palm up",
    target: "calves",
  },
  {
    bodyPart: "lower arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/WaXqf-wcJTIOz7",
    id: "0401",
    name: "dumbbell seated palms up wrist curl",
    target: "forearms",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/LVmimVR5jGk0YC",
    id: "0402",
    name: "dumbbell seated preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qV6-v3WKUiKnRO",
    id: "0403",
    name: "dumbbell seated revers grip concentration curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/L2Lk72YAQQiJOO",
    id: "1738",
    name: "dumbbell seated reverse grip one arm overhead tricep extension",
    target: "triceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/1evdfd0RrfeUU6",
    id: "0405",
    name: "dumbbell seated shoulder press",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/fVHKjx9dr29Dwx",
    id: "0404",
    name: "dumbbell seated shoulder press (parallel grip)",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/sO88zc9HGH-oHf",
    id: "2188",
    name: "dumbbell seated triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "back",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ft-f1BWcVBDkx0",
    id: "0406",
    name: "dumbbell shrug",
    target: "traps",
  },
  {
    bodyPart: "waist",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/5IlEJuIN-FdQ5Y",
    id: "0407",
    name: "dumbbell side bend",
    target: "abs",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/W7x1cmhfnY7KVN",
    id: "0408",
    name: "dumbbell side lying one hand raise",
    target: "delts",
  },
  {
    bodyPart: "back",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/1yHQrz0rzmTI9R",
    id: "3664",
    name: "dumbbell side plank with rear fly",
    target: "upper back",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/mTrsX9jjhNl8wE",
    id: "3548",
    name: "dumbbell single arm overhead carry",
    target: "delts",
  },
  {
    bodyPart: "lower legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/CE6bJEbCCz86Kk",
    id: "0409",
    name: "dumbbell single leg calf raise",
    target: "calves",
  },
  {
    bodyPart: "upper legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/P3TjLDLVAWaQGh",
    id: "1757",
    name: "dumbbell single leg deadlift",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Yh6J3oo-Zk55KO",
    id: "2805",
    name: "dumbbell single leg deadlift with stepbox support",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ojQhysSjusJfGW",
    id: "0410",
    name: "dumbbell single leg split squat",
    target: "quads",
  },
  {
    bodyPart: "upper legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/fb3285ybbApWgx",
    id: "0411",
    name: "dumbbell single leg squat",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/MF8VLanqOLMRqQ",
    id: "0413",
    name: "dumbbell squat",
    target: "glutes",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/x3FZstzh-Ni2ou",
    id: "3560",
    name: "dumbbell standing alternate hammer curl and press",
    target: "biceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/GtsokxfKZYZHvl",
    id: "0414",
    name: "dumbbell standing alternate overhead press",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/2RAQgeS1H0uTHQ",
    id: "0415",
    name: "dumbbell standing alternate raise",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/eVEtqHkPNbEtES",
    id: "1739",
    name: "dumbbell standing alternating tricep kickback",
    target: "triceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/SS0nO9PQsL5CCt",
    id: "2143",
    name: "dumbbell standing around world",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/YxnwZcjIpI83W2",
    id: "1740",
    name: "dumbbell standing bent over one arm triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/irLxNri-qGbxuN",
    id: "1741",
    name: "dumbbell standing bent over two arm triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/EE6G54nJprmDp6",
    id: "0416",
    name: "dumbbell standing biceps curl",
    target: "biceps",
  },
  {
    bodyPart: "lower legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/5vSHMw9xAWqkBm",
    id: "0417",
    name: "dumbbell standing calf raise",
    target: "calves",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/XcG9bNcD9HN6in",
    id: "0418",
    name: "dumbbell standing concentration curl",
    target: "biceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Z3VQ9D4nDs7FOX",
    id: "0419",
    name: "dumbbell standing front raise above head",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Ndys8HHUAZS0js",
    id: "2321",
    name: "dumbbell standing inner biceps curl v. 2",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/-zJ8ZVKl7NvT3t",
    id: "0420",
    name: "dumbbell standing kickback",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Oc-OKFHmutxqL4",
    id: "0421",
    name: "dumbbell standing one arm concentration curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ETaAwtW31ZAup9",
    id: "0422",
    name: "dumbbell standing one arm curl (over incline bench)",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/3XPMMCP8caUGEq",
    id: "1680",
    name: "dumbbell standing one arm curl over incline bench",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/A355hIEBStPK87",
    id: "0423",
    name: "dumbbell standing one arm extension",
    target: "triceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/yVE4KISrncJ4dQ",
    id: "0424",
    name: "dumbbell standing one arm palm in press",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/M-ioVRdW0XjjDW",
    id: "0425",
    name: "dumbbell standing one arm reverse curl",
    target: "biceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/EPRj4jFfnVpjWD",
    id: "0426",
    name: "dumbbell standing overhead press",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/-jiQ-A56bArZhc",
    id: "0427",
    name: "dumbbell standing palms in press",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/6dmpZzp-CSGmL2",
    id: "0428",
    name: "dumbbell standing preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/zCwLPoU2zXW21t",
    id: "0429",
    name: "dumbbell standing reverse curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/SSdrSFgfXU6CKg",
    id: "0430",
    name: "dumbbell standing triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/WjXPdNqs3Tz-jk",
    id: "2293",
    name: "dumbbell standing zottman preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/3UcnaCdEeUGvIk",
    id: "1684",
    name: "dumbbell step up single leg balance with bicep curl",
    target: "biceps",
  },
  {
    bodyPart: "upper legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/CDA7sWG6zYkHus",
    id: "0431",
    name: "dumbbell step-up",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/6MpiQhMefFnA9I",
    id: "2796",
    name: "dumbbell step-up lunge",
    target: "quads",
  },
  {
    bodyPart: "upper legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/GNQrG73LIWxs32",
    id: "2812",
    name: "dumbbell step-up split squat",
    target: "quads",
  },
  {
    bodyPart: "upper legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/QrZmFQoRdpYCCd",
    id: "0432",
    name: "dumbbell stiff leg deadlift",
    target: "glutes",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/TnlWwYHQ6gIgsn",
    id: "0433",
    name: "dumbbell straight arm pullover",
    target: "pectorals",
  },
  {
    bodyPart: "upper legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/CXlTKytCTYRtvc",
    id: "0434",
    name: "dumbbell straight leg deadlift",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/KfmgJr8ZzlE1YN",
    id: "2808",
    name: "dumbbell sumo pull through",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/w8uXKka8Raxx3x",
    id: "2803",
    name: "dumbbell supported squat",
    target: "quads",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/EE2zQQGnTDdhJ-",
    id: "0436",
    name: "dumbbell tate press",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/xcBQ9KTFqkdzXe",
    id: "1742",
    name: "dumbbell tricep kickback with stork stance",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/7PNJ3UlzGnLSnD",
    id: "1743",
    name: "dumbbell twisting bench press",
    target: "triceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/opFknn0z9QAjeh",
    id: "0437",
    name: "dumbbell upright row",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/9KJMYawOkNkdon",
    id: "1765",
    name: "dumbbell upright row (back pov)",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ogzVryPX8Euasn",
    id: "0864",
    name: "dumbbell upright shoulder external rotation",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/tWnNQ1YTAcPjzK",
    id: "5201",
    name: "dumbbell waiter biceps curl",
    target: "biceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/PTQoolZl0IrGtl",
    id: "0438",
    name: "dumbbell w-press",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/UBc1XEuXYMIvH1",
    id: "0439",
    name: "dumbbell zottman curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/hl0B7bUUQfRtS-",
    id: "2294",
    name: "dumbbell zottman preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/-AWUxDZaz9EBIm",
    id: "2189",
    name: "dumbbells seated triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/QQuyblKucKFXDF",
    id: "1167",
    name: "dynamic chest stretch (male)",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/3VXn0iAC8IiKXq",
    id: "3287",
    name: "elbow dips",
    target: "triceps",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/p-4LNjUWCow95L",
    id: "1772",
    name: "elbow lift - reverse push-up",
    target: "upper back",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/4M8wmctewxBucn",
    id: "0443",
    name: "elbow-to-knee",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/CHoZvFsY6lvLb3",
    id: "3292",
    name: "elevator",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/rP5XPebzvq6DIL",
    id: "1332",
    name: "exercise ball alternating arm ups",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/1Jut-hiQt5vRUv",
    id: "1333",
    name: "exercise ball back extension with arms extended",
    target: "spine",
  },
  {
    bodyPart: "back",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Cno0Ju-Bd-arbm",
    id: "1334",
    name: "exercise ball back extension with hands behind head",
    target: "spine",
  },
  {
    bodyPart: "back",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/V9qkHquJTlxF24",
    id: "1335",
    name: "exercise ball back extension with knees off ground",
    target: "spine",
  },
  {
    bodyPart: "back",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/OnPalQcKnfdGuJ",
    id: "1336",
    name: "exercise ball back extension with rotation",
    target: "spine",
  },
  {
    bodyPart: "upper arms",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/0IvpMj7inXdHWs",
    id: "1744",
    name: "exercise ball dip",
    target: "triceps",
  },
  {
    bodyPart: "upper legs",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/0zD0rmcQ54TdYy",
    id: "1559",
    name: "exercise ball hip flexor stretch",
    target: "glutes",
  },
  {
    bodyPart: "back",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/GrDrsye-3KyiKt",
    id: "1338",
    name: "exercise ball hug",
    target: "spine",
  },
  {
    bodyPart: "back",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/z5tr4qiAviQ9hw",
    id: "1339",
    name: "exercise ball lat stretch",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/THDnhTs3sEAXT4",
    id: "1341",
    name: "exercise ball lower back stretch (pyramid)",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/pPQrrGwXeuhUbI",
    id: "1342",
    name: "exercise ball lying side lat stretch",
    target: "lats",
  },
  {
    bodyPart: "lower legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/VrhxSwgs-Rl0FJ",
    id: "1382",
    name: "exercise ball on the wall calf raise",
    target: "calves",
  },
  {
    bodyPart: "lower legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/or-DR0h5xSPtYR",
    id: "3241",
    name: "exercise ball on the wall calf raise (tennis ball between ankles)",
    target: "calves",
  },
  {
    bodyPart: "lower legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Q3Y-HqAwGMTRP1",
    id: "3240",
    name: "exercise ball on the wall calf raise (tennis ball between knees)",
    target: "calves",
  },
  {
    bodyPart: "upper legs",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/oKMVtVzmnOYjQx",
    id: "1416",
    name: "exercise ball one leg prone lower body rotation",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/3CstQsr1-mtIsR",
    id: "1417",
    name: "exercise ball one legged diagonal kick hamstring curl",
    target: "glutes",
  },
  {
    bodyPart: "chest",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/RkrmmBkRmqd0fX",
    id: "1296",
    name: "exercise ball pike push up",
    target: "pectorals",
  },
  {
    bodyPart: "back",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/7Wa9wghZeh044Y",
    id: "1343",
    name: "exercise ball prone leg raise",
    target: "spine",
  },
  {
    bodyPart: "upper legs",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/uG4k6t3clYedYA",
    id: "1560",
    name: "exercise ball seated hamstring stretch",
    target: "hamstrings",
  },
  {
    bodyPart: "upper arms",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/EMSl07CpHRp8Az",
    id: "1745",
    name: "exercise ball seated triceps stretch",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/pnzH7w7GzcEw8t",
    id: "1746",
    name: "exercise ball supine triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "ez barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/bWP36tb3DAs3cU",
    id: "1747",
    name: "ez bar french press on exercise ball",
    target: "triceps",
  },
  {
    bodyPart: "back",
    equipment: "ez barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ePQx1dAhXNVbTS",
    id: "3010",
    name: "ez bar lying bent arms pullover",
    target: "lats",
  },
  {
    bodyPart: "upper arms",
    equipment: "ez barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/fIRJYkXNzaEVAX",
    id: "1748",
    name: "ez bar lying close grip triceps extension behind head",
    target: "triceps",
  },
  {
    bodyPart: "back",
    equipment: "ez barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/yWBNTGaFH2lDYH",
    id: "1344",
    name: "ez bar reverse grip bent over row",
    target: "upper back",
  },
  {
    bodyPart: "upper arms",
    equipment: "ez barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Q1iBC0FYYYfgDJ",
    id: "1682",
    name: "ez bar seated close grip concentration curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "ez barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Ourp2q7rPPvzfI",
    id: "1749",
    name: "ez bar standing french press",
    target: "triceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "ez barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/GJzDi9lP-cOCiO",
    id: "0445",
    name: "ez barbell anti gravity press",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "ez barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/cY4UZn0w2J3-pi",
    id: "1627",
    name: "ez barbell close grip preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "ez barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ryKOy9xwOVAFow",
    id: "0446",
    name: "ez barbell close-grip curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "ez barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/RdnU4r7JTcoHPH",
    id: "0447",
    name: "ez barbell curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "ez barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/pF86kwcqtC3TO1",
    id: "0448",
    name: "ez barbell decline close grip face press",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "ez barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/5lxHoTvFj-XFPE",
    id: "2186",
    name: "ez barbell decline triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "ez barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/rU7SIQNR3iccml",
    id: "0449",
    name: "ez barbell incline triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "ez barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/VYEv06NnE4u9UN",
    id: "0450",
    name: "ez barbell jm bench press",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "ez barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/A9S8k7RHS0BFZa",
    id: "0451",
    name: "ez barbell reverse grip curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "ez barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/SFJ64L4feuimYw",
    id: "0452",
    name: "ez barbell reverse grip preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "ez barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/BvQBtVNLQHy8Tx",
    id: "1458",
    name: "ez barbell seated curls",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "ez barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/b71thL2kKN3Q4u",
    id: "0453",
    name: "ez barbell seated triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "ez barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/6XnlAR5xV1u6oe",
    id: "0454",
    name: "ez barbell spider curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "ez barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/s0ks-V0BQ12Zyj",
    id: "1628",
    name: "ez barbell spider curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "ez barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/2rZ7k5qmISebVx",
    id: "2404",
    name: "ez-bar biceps curl (with arm blaster)",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "ez barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/IfDN-AUCePZL9c",
    id: "2432",
    name: "ez-bar close-grip bench press",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "ez barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/DHvU710OgSk29F",
    id: "2741",
    name: "ez-barbell standing wide grip biceps curl",
    target: "biceps",
  },
  {
    bodyPart: "upper legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/0JMSBb6eoQKEho",
    id: "2133",
    name: "farmers walk",
    target: "quads",
  },
  {
    bodyPart: "lower arms",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/eGdW1fOZZOYcCF",
    id: "0455",
    name: "finger curls",
    target: "forearms",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Ox8bVr1oqOLu5p",
    id: "3303",
    name: "flag",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qBZ8ZtbtNpgjzK",
    id: "0456",
    name: "flexion leg sit up (bent knee)",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/YuqYo9rq7HQjXL",
    id: "0457",
    name: "flexion leg sit up (straight arm)",
    target: "abs",
  },
  {
    bodyPart: "chest",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/1Xdy1jAGHXEape",
    id: "0458",
    name: "floor fly (with barbell)",
    target: "pectorals",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/FXOi8rBTumzax6",
    id: "0459",
    name: "flutter kicks",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/pLVVR0moEXtabm",
    id: "1472",
    name: "forward jump",
    target: "quads",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/x9eslgrc3TdcJV",
    id: "3470",
    name: "forward lunge (male)",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/bMUt4v4uI0SPdN",
    id: "3194",
    name: "frankenstein squat",
    target: "glutes",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/iNRAUO3GSR-eS2",
    id: "2429",
    name: "frog crunch",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/7D9IAZI0KileWJ",
    id: "3301",
    name: "frog planche",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/NDOA3b9rMtkI-6",
    id: "3296",
    name: "front lever",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/GFVYJ69QHKXVwi",
    id: "3295",
    name: "front lever reps",
    target: "upper back",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/AOcA8ZAZmeKWkh",
    id: "0464",
    name: "front plank with twist",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/-VRpOEk1-XQl6L",
    id: "3315",
    name: "full maltese",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/TU5cceJs5vCk22",
    id: "3299",
    name: "full planche",
    target: "abs",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/6LQBPAHk61RSyP",
    id: "3327",
    name: "full planche push-up",
    target: "pectorals",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/tcxNhSU6YHsXBY",
    id: "0466",
    name: "gironda sternum chin",
    target: "lats",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/h916xfZivthYCs",
    id: "3561",
    name: "glute bridge march",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/eOpye9kRAZyLIX",
    id: "3523",
    name: "glute bridge two legs on bench (male)",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/LPZWx5iYpIXSDq",
    id: "3193",
    name: "glute-ham raise",
    target: "hamstrings",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/lGqWxl59BHlqx5",
    id: "0467",
    name: "gorilla chin",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/gEi2wQ2CytnDgj",
    id: "0469",
    name: "groin crunch",
    target: "abs",
  },
  {
    bodyPart: "lower legs",
    equipment: "sled machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/A-xMqGBO54PbXw",
    id: "1383",
    name: "hack calf raise",
    target: "calves",
  },
  {
    bodyPart: "lower legs",
    equipment: "sled machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/T2KfqRe-KBPfIj",
    id: "1384",
    name: "hack one leg calf raise",
    target: "calves",
  },
  {
    bodyPart: "cardio",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/f3OQeUbGmYJK4I",
    id: "3221",
    name: "half knee bends (male)",
    target: "cardiovascular system",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/gOXpv747R8myVp",
    id: "3202",
    name: "half sit-up (male)",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/TPp5qVPiZ1hz6s",
    id: "1511",
    name: "hamstring stretch",
    target: "hamstrings",
  },
  {
    bodyPart: "chest",
    equipment: "upper body ergometer",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/eox8vASCXaOwMq",
    id: "2139",
    name: "hands bike",
    target: "pectorals",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qWebec7JxCduLI",
    id: "3218",
    name: "hands clasped circular toe touch (male)",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/8joLXFyzElznRD",
    id: "3215",
    name: "hands reversed clasped circular toe touch (male)",
    target: "glutes",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/jDkYSWtLMvYKPs",
    id: "3302",
    name: "handstand",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Sw8AJ8SJEE5RGC",
    id: "0471",
    name: "handstand push-up",
    target: "triceps",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/aYeu62C99X0Lqt",
    id: "1764",
    name: "hanging leg hip raise",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/o5pjmxv9zVKvBf",
    id: "0472",
    name: "hanging leg raise",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kQ7xfFpTVydokL",
    id: "1761",
    name: "hanging oblique knee raise",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/blkMdkadl6e00X",
    id: "0473",
    name: "hanging pike",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/GQQFbwuPZD3Wun",
    id: "0474",
    name: "hanging straight leg hip raise",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/rLSCy1nlpLSdt6",
    id: "0475",
    name: "hanging straight leg raise",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Q672GwIQ9yZe4L",
    id: "0476",
    name: "hanging straight twisting leg hip raise",
    target: "abs",
  },
  {
    bodyPart: "cardio",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/i7MqJZygBMrghr",
    id: "3636",
    name: "high knee against wall",
    target: "cardiovascular system",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/dpYMTvMYhf6XFq",
    id: "0484",
    name: "hip raise (bent knee)",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/x3DFgCWILwRF0I",
    id: "1418",
    name: "hug keens to chest",
    target: "glutes",
  },
  {
    bodyPart: "chest",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/XHaGO1XNxZRqEn",
    id: "3234",
    name: "hyght dumbbell fly",
    target: "pectorals",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/BtlQOYrPWSnPqU",
    id: "0489",
    name: "hyperextension",
    target: "spine",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/l9cXRep6H1mKaI",
    id: "0488",
    name: "hyperextension (on bench)",
    target: "spine",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Kub0f6fgZetp9L",
    id: "3289",
    name: "impossible dips",
    target: "triceps",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/BHn0GMStHuh-eM",
    id: "1471",
    name: "inchworm",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/TXjbbB1lGvbHOn",
    id: "3698",
    name: "inchworm v. 2",
    target: "abs",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/rj84VjTuWpzgMN",
    id: "0490",
    name: "incline close-grip push-up",
    target: "triceps",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/fqIHwxM1sblFZd",
    id: "0491",
    name: "incline leg hip raise (leg straight)",
    target: "abs",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/XrZIEnzAmKUDI5",
    id: "0492",
    name: "incline push up depth jump",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/92fEPsBaiRFV2i",
    id: "0493",
    name: "incline push-up",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/F9jjMt5jNO6YNI",
    id: "3785",
    name: "incline push-up (on box)",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qNCS-7mk1wELUw",
    id: "0494",
    name: "incline reverse grip push-up",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/sgVeS5UiKrLH2l",
    id: "3011",
    name: "incline scapula push up",
    target: "serratus anterior",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/nydMxYRPeSoxSO",
    id: "0495",
    name: "incline twisting sit-up",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "rope",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/pUIDw6HvFwXSMY",
    id: "1564",
    name: "intermediate hip flexor and quad stretch",
    target: "quads",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/rRxU3PswmCx8ze",
    id: "0496",
    name: "inverse leg curl (bench support)",
    target: "hamstrings",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/lAxra2M1zIjlUF",
    id: "2400",
    name: "inverse leg curl (on pull-up cable machine)",
    target: "hamstrings",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/rkwBBuZYUOqLoL",
    id: "0499",
    name: "inverted row",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/NsGZqIugshoBDa",
    id: "2300",
    name: "inverted row bent knees",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/cZLoBg3dQKiWji",
    id: "2298",
    name: "inverted row on bench",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/S4KztmZ7TsUSsN",
    id: "0497",
    name: "inverted row v. 2",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/CVDZQnFFsOVaQO",
    id: "0498",
    name: "inverted row with straps",
    target: "upper back",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/OREIFjmCZ6up1X",
    id: "1419",
    name: "iron cross stretch",
    target: "glutes",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/fYoLN6ixZwmjZ4",
    id: "1297",
    name: "isometric chest squeeze",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qj90yC6cFFGRjh",
    id: "0500",
    name: "isometric wipers",
    target: "pectorals",
  },
  {
    bodyPart: "cardio",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/JZ90rlk7IsWdrB",
    id: "0501",
    name: "jack burpee",
    target: "cardiovascular system",
  },
  {
    bodyPart: "cardio",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/gPZAjVdxLWbbxo",
    id: "3224",
    name: "jack jump (male)",
    target: "cardiovascular system",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/de5H8-ZlYDwfdZ",
    id: "0507",
    name: "jackknife sit-up",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/BH07pO4zpo-jTE",
    id: "0508",
    name: "janda sit-up",
    target: "abs",
  },
  {
    bodyPart: "cardio",
    equipment: "rope",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/zMRWN8vm5-Blgt",
    id: "2612",
    name: "jump rope",
    target: "cardiovascular system",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qQ79gcui56sGHZ",
    id: "0514",
    name: "jump squat",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/LxfDODFr373zst",
    id: "0513",
    name: "jump squat v. 2",
    target: "glutes",
  },
  {
    bodyPart: "waist",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/0kTkOAIuKAxbWt",
    id: "0517",
    name: "kettlebell advanced windmill",
    target: "abs",
  },
  {
    bodyPart: "lower arms",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/5NKYALHdnyBQ6U",
    id: "0518",
    name: "kettlebell alternating hang clean",
    target: "forearms",
  },
  {
    bodyPart: "shoulders",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/1DsdxrosylfpjJ",
    id: "0520",
    name: "kettlebell alternating press",
    target: "delts",
  },
  {
    bodyPart: "chest",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/srFCgyQtoEFKIr",
    id: "0519",
    name: "kettlebell alternating press on floor",
    target: "pectorals",
  },
  {
    bodyPart: "back",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/TCZgfAX-GOQCmJ",
    id: "0521",
    name: "kettlebell alternating renegade row",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/IWXlHd00VC2EcU",
    id: "0522",
    name: "kettlebell alternating row",
    target: "upper back",
  },
  {
    bodyPart: "shoulders",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/9qYlBLoXge8ADV",
    id: "0523",
    name: "kettlebell arnold press",
    target: "delts",
  },
  {
    bodyPart: "waist",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/gWCD7adz6y78TV",
    id: "0524",
    name: "kettlebell bent press",
    target: "abs",
  },
  {
    bodyPart: "upper arms",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/xTjiX3f7sJegS-",
    id: "0525",
    name: "kettlebell bottoms up clean from the hang position",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/uZe3IhXO1xOUpS",
    id: "0526",
    name: "kettlebell double alternating hang clean",
    target: "biceps",
  },
  {
    bodyPart: "shoulders",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/sBj0HmZ4i18CTh",
    id: "0527",
    name: "kettlebell double jerk",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/DjO3JHl3cb6U1E",
    id: "0528",
    name: "kettlebell double push press",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/X460yrbjLpJKdp",
    id: "0529",
    name: "kettlebell double snatch",
    target: "delts",
  },
  {
    bodyPart: "waist",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Yal37UGUysAZ3g",
    id: "0530",
    name: "kettlebell double windmill",
    target: "abs",
  },
  {
    bodyPart: "chest",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ks6l0tFW1WXxyL",
    id: "0531",
    name: "kettlebell extended range one arm press on floor",
    target: "pectorals",
  },
  {
    bodyPart: "waist",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/OgQuv3LfO7rpp7",
    id: "0532",
    name: "kettlebell figure 8",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/cm9mE510FCW2ih",
    id: "0533",
    name: "kettlebell front squat",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/EZ-yF2amOYMSSa",
    id: "0534",
    name: "kettlebell goblet squat",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/YPsD7lxQDNhFDs",
    id: "0535",
    name: "kettlebell hang clean",
    target: "hamstrings",
  },
  {
    bodyPart: "upper legs",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/arXKj8qibSwYs5",
    id: "0536",
    name: "kettlebell lunge pass through",
    target: "glutes",
  },
  {
    bodyPart: "shoulders",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/fkodRRUZyqUJQO",
    id: "0537",
    name: "kettlebell one arm clean and jerk",
    target: "delts",
  },
  {
    bodyPart: "chest",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/u4Z1NrXgrwrWIM",
    id: "1298",
    name: "kettlebell one arm floor press",
    target: "pectorals",
  },
  {
    bodyPart: "shoulders",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/H25rFltmCpGW5W",
    id: "0538",
    name: "kettlebell one arm jerk",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/-PhtncBbUQ7KP-",
    id: "0539",
    name: "kettlebell one arm military press to the side",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/W2KtYJSO1-EqcZ",
    id: "0540",
    name: "kettlebell one arm push press",
    target: "delts",
  },
  {
    bodyPart: "back",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/8F8bWpRXwQrx6Z",
    id: "0541",
    name: "kettlebell one arm row",
    target: "upper back",
  },
  {
    bodyPart: "shoulders",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/jdxKgkgwuCeejS",
    id: "0542",
    name: "kettlebell one arm snatch",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/emQfEDEvHHoQ-n",
    id: "0543",
    name: "kettlebell pirate supper legs",
    target: "delts",
  },
  {
    bodyPart: "upper legs",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/vK200VctHYJJU9",
    id: "0544",
    name: "kettlebell pistol squat",
    target: "glutes",
  },
  {
    bodyPart: "chest",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/aGA6sCU2YIDWJu",
    id: "0545",
    name: "kettlebell plyo push-up",
    target: "pectorals",
  },
  {
    bodyPart: "shoulders",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/lvE6850sv1iPcP",
    id: "0546",
    name: "kettlebell seated press",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/6Z5b-V2GQlUy1A",
    id: "1438",
    name: "kettlebell seated two arm military press",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/rK-0o8jFWF9bUS",
    id: "0547",
    name: "kettlebell seesaw press",
    target: "delts",
  },
  {
    bodyPart: "back",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/rklrSu5WyLG7IR",
    id: "0548",
    name: "kettlebell sumo high pull",
    target: "traps",
  },
  {
    bodyPart: "upper legs",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/MzsVz8zkRlcCME",
    id: "0549",
    name: "kettlebell swing",
    target: "glutes",
  },
  {
    bodyPart: "shoulders",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/M3evcDqIU8sR91",
    id: "0550",
    name: "kettlebell thruster",
    target: "delts",
  },
  {
    bodyPart: "upper legs",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Kxm1gd9U-ihSDk",
    id: "0551",
    name: "kettlebell turkish get up (squat style)",
    target: "glutes",
  },
  {
    bodyPart: "shoulders",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/9cd9crWbM3Hboc",
    id: "0552",
    name: "kettlebell two arm clean",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/8ZptQTgkfm1nD3",
    id: "0553",
    name: "kettlebell two arm military press",
    target: "delts",
  },
  {
    bodyPart: "back",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/WzEdz-pEUB8fJ3",
    id: "1345",
    name: "kettlebell two arm row",
    target: "upper back",
  },
  {
    bodyPart: "waist",
    equipment: "kettlebell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Ym0OzJ9hFxOeqG",
    id: "0554",
    name: "kettlebell windmill",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/aTX0TgpMPPShB8",
    id: "0555",
    name: "kick out sit",
    target: "hamstrings",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Axe2ZXjMFcdDW6",
    id: "0558",
    name: "kipping muscle up",
    target: "lats",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/4IxfossPBBKutl",
    id: "3640",
    name: "knee touch crunch",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/PBKe-OWOs5rM8K",
    id: "1420",
    name: "kneeling jump squat",
    target: "glutes",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/5jcovKY1D2cDdF",
    id: "1346",
    name: "kneeling lat stretch",
    target: "lats",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/MHhqUllD6xzvAj",
    id: "3239",
    name: "kneeling plank tap shoulder (male)",
    target: "abs",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/vDjCaLCUf0Uag8",
    id: "3211",
    name: "kneeling push-up (male)",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/3OCEAEkloIjBP1",
    id: "3288",
    name: "korean dips",
    target: "pectorals",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/1mbjqtCI-6HIxt",
    id: "3418",
    name: "l-pull-up",
    target: "lats",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/YJA07BMbf2qWAC",
    id: "3419",
    name: "l-sit on floor",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/CreCuWeM3jzI60",
    id: "0562",
    name: "landmine 180",
    target: "abs",
  },
  {
    bodyPart: "shoulders",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Lc6-ZaHONek3Ki",
    id: "3237",
    name: "landmine lateral raise",
    target: "delts",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/emgUmeL0noUrQe",
    id: "3300",
    name: "lean planche",
    target: "abs",
  },
  {
    bodyPart: "shoulders",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/GAXLirVsIHeFV4",
    id: "2271",
    name: "left hook. boxing",
    target: "delts",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/h4bk2Jx86X6Czb",
    id: "0570",
    name: "leg pull in flat bench",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/fFp0WSM6CS0xjX",
    id: "1576",
    name: "leg up hamstring stretch",
    target: "hamstrings",
  },
  {
    bodyPart: "upper legs",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/oHlJfHri3OrxOL",
    id: "2287",
    name: "lever alternate leg press ",
    target: "quads",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/PNVmbmAcSn-RY9",
    id: "0571",
    name: "lever alternating narrow grip seated row ",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/j3t7O80epDhzuY",
    id: "0572",
    name: "lever assisted chin-up",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/iCm6I9i-aH8k-8",
    id: "0573",
    name: "lever back extension",
    target: "spine",
  },
  {
    bodyPart: "back",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/gbXqKCAdHAmvZP",
    id: "0574",
    name: "lever bent over row ",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/cnc5uvgYRlgYbs",
    id: "3200",
    name: "lever bent-over row with v-bar ",
    target: "upper back",
  },
  {
    bodyPart: "upper arms",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/c2LPFP7sSP0TF2",
    id: "0575",
    name: "lever bicep curl",
    target: "biceps",
  },
  {
    bodyPart: "lower legs",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/lf-afJ-NBhyJJ7",
    id: "2289",
    name: "lever calf press ",
    target: "calves",
  },
  {
    bodyPart: "chest",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/8RZszCLvhuPWkG",
    id: "0577",
    name: "lever chest press",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Y2cq-W3ag5tibk",
    id: "0576",
    name: "lever chest press ",
    target: "pectorals",
  },
  {
    bodyPart: "upper legs",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ebDt-ZWAElEisR",
    id: "0578",
    name: "lever deadlift ",
    target: "glutes",
  },
  {
    bodyPart: "chest",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/clsc8NZitE1vgz",
    id: "1300",
    name: "lever decline chest press",
    target: "pectorals",
  },
  {
    bodyPart: "lower legs",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/QkMVpbbT6R3Srd",
    id: "1253",
    name: "lever donkey calf raise",
    target: "calves",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/rUMbReda6PuRjt",
    id: "0579",
    name: "lever front pulldown",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/cP4bncXyIo1BCE",
    id: "0580",
    name: "lever gripless shrug",
    target: "traps",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/yJB4b9czYmczBb",
    id: "1439",
    name: "lever gripless shrug v. 2",
    target: "traps",
  },
  {
    bodyPart: "lower arms",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/s325vCpuUVZUKF",
    id: "2288",
    name: "lever gripper hands ",
    target: "forearms",
  },
  {
    bodyPart: "upper arms",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/pQVkWhohTqF68L",
    id: "1615",
    name: "lever hammer grip preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/G0mox0MTrhWE2A",
    id: "0581",
    name: "lever high row ",
    target: "upper back",
  },
  {
    bodyPart: "upper legs",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/M7PjRuTXYZS7Q5",
    id: "2286",
    name: "lever hip extension v. 2",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qrc9AM4T6ec6rO",
    id: "2611",
    name: "lever horizontal one leg press",
    target: "glutes",
  },
  {
    bodyPart: "chest",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/95ELk2acMRqyZ6",
    id: "1299",
    name: "lever incline chest press",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/CvIicvOigq0wf4",
    id: "1479",
    name: "lever incline chest press v. 2",
    target: "pectorals",
  },
  {
    bodyPart: "upper legs",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/YEIhZPGTv-eZoO",
    id: "0582",
    name: "lever kneeling leg curl ",
    target: "hamstrings",
  },
  {
    bodyPart: "waist",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Gxj4UpVd2qf2Fx",
    id: "0583",
    name: "lever kneeling twist",
    target: "abs",
  },
  {
    bodyPart: "shoulders",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/xpStLGjlnLXT7h",
    id: "0584",
    name: "lever lateral raise",
    target: "delts",
  },
  {
    bodyPart: "upper legs",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/IaFHMe77Km3pzx",
    id: "0585",
    name: "lever leg extension",
    target: "quads",
  },
  {
    bodyPart: "upper legs",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/xu9CAV-dh5O0QS",
    id: "0586",
    name: "lever lying leg curl",
    target: "hamstrings",
  },
  {
    bodyPart: "upper legs",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/5KjeCNQXmUOB56",
    id: "3195",
    name: "lever lying two-one leg curl",
    target: "hamstrings",
  },
  {
    bodyPart: "shoulders",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/rSe1GufHvZd4Zh",
    id: "0587",
    name: "lever military press ",
    target: "delts",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Lx-lcF3Ih1Y0xo",
    id: "0588",
    name: "lever narrow grip seated row ",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/K0b1ENULg3tyAp",
    id: "0589",
    name: "lever one arm bent over row ",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/k-4ez1S-iFq6TW",
    id: "1356",
    name: "lever one arm lateral high row",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/X3zzaGuPUXWwYD",
    id: "1347",
    name: "lever one arm lateral wide pulldown ",
    target: "lats",
  },
  {
    bodyPart: "shoulders",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/bHQe2nYpD8ETv-",
    id: "0590",
    name: "lever one arm shoulder press ",
    target: "delts",
  },
  {
    bodyPart: "upper arms",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/S-YdXI8s8B7ia9",
    id: "0591",
    name: "lever overhand triceps dip",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/-YUXOTpw638F0W",
    id: "0592",
    name: "lever preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/9UmWkMZ8oAOiVI",
    id: "1614",
    name: "lever preacher curl v. 2",
    target: "biceps",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/YGpxog3X9oq7aC",
    id: "2285",
    name: "lever pullover ",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/B48v3gBJZy0-Q8",
    id: "2736",
    name: "lever reverse grip lateral pulldown ",
    target: "lats",
  },
  {
    bodyPart: "upper arms",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/AS3OzCBBb-gOas",
    id: "1616",
    name: "lever reverse grip preacher curl",
    target: "biceps",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Vkg3kJF4keqxKA",
    id: "1348",
    name: "lever reverse grip vertical row",
    target: "upper back",
  },
  {
    bodyPart: "upper legs",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/w9GaeqLzJSvuTp",
    id: "0593",
    name: "lever reverse hyperextension ",
    target: "glutes",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Nkbbn7ndNA6bOr",
    id: "1349",
    name: "lever reverse t-bar row",
    target: "upper back",
  },
  {
    bodyPart: "lower legs",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/zFWjSdUqVUdy9R",
    id: "2315",
    name: "lever rotary calf",
    target: "calves",
  },
  {
    bodyPart: "lower legs",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/R97eTRRfLQvZ7-",
    id: "2335",
    name: "lever seated calf press",
    target: "calves",
  },
  {
    bodyPart: "lower legs",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/IOpHP38LnCTNWE",
    id: "0594",
    name: "lever seated calf raise ",
    target: "calves",
  },
  {
    bodyPart: "waist",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/nZMpCOXXZHW86X",
    id: "1452",
    name: "lever seated crunch",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/K75Tv2ezBOm4a8",
    id: "0595",
    name: "lever seated crunch (chest pad)",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/a1eZ-ygC5R7j9Q",
    id: "3760",
    name: "lever seated crunch v. 2",
    target: "abs",
  },
  {
    bodyPart: "upper arms",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/6L6TLIJgggixsq",
    id: "1451",
    name: "lever seated dip",
    target: "triceps",
  },
  {
    bodyPart: "chest",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/guevCSa0HqRMAX",
    id: "0596",
    name: "lever seated fly",
    target: "pectorals",
  },
  {
    bodyPart: "upper legs",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/XxScOG5YEtiEqb",
    id: "3759",
    name: "lever seated good morning",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/UMcyfTgfmPHFTx",
    id: "0597",
    name: "lever seated hip abduction",
    target: "abductors",
  },
  {
    bodyPart: "upper legs",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/FtQzRrEbeov6PU",
    id: "0598",
    name: "lever seated hip adduction",
    target: "adductors",
  },
  {
    bodyPart: "upper legs",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/mWh2OfeknFY5jA",
    id: "0599",
    name: "lever seated leg curl",
    target: "hamstrings",
  },
  {
    bodyPart: "waist",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/bixEVOPp7mt42S",
    id: "0600",
    name: "lever seated leg raise crunch ",
    target: "abs",
  },
  {
    bodyPart: "shoulders",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/VWSeUKNKkOIquF",
    id: "0602",
    name: "lever seated reverse fly",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/JyKHLwzJVBkD5z",
    id: "0601",
    name: "lever seated reverse fly (parallel grip)",
    target: "delts",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/oK9iVqzuCVijC9",
    id: "1350",
    name: "lever seated row",
    target: "upper back",
  },
  {
    bodyPart: "lower legs",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/v8-Ci19OEf9Djr",
    id: "1385",
    name: "lever seated squat calf raise on leg press machine",
    target: "calves",
  },
  {
    bodyPart: "shoulders",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/hU8TFvx2E80koY",
    id: "0603",
    name: "lever shoulder press ",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/EYw6X28FH20kiZ",
    id: "0869",
    name: "lever shoulder press  v. 2",
    target: "delts",
  },
  {
    bodyPart: "shoulders",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/XXztMspNK7jxly",
    id: "2318",
    name: "lever shoulder press  v. 3",
    target: "delts",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/r1vOE1lblmx-1r",
    id: "0604",
    name: "lever shrug ",
    target: "traps",
  },
  {
    bodyPart: "lower legs",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/bzVt3c59ISDz2g",
    id: "0605",
    name: "lever standing calf raise",
    target: "calves",
  },
  {
    bodyPart: "chest",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kkSHESNI87p314",
    id: "3758",
    name: "lever standing chest press",
    target: "pectorals",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/WQPqAyfMX4WcDL",
    id: "0606",
    name: "lever t bar row ",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/lLUmJg9coWRNvj",
    id: "1351",
    name: "lever t-bar reverse grip row",
    target: "upper back",
  },
  {
    bodyPart: "upper arms",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/KsWzgrJR2Y9dDT",
    id: "0607",
    name: "lever triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/2KMsPw6kE1HN0r",
    id: "1313",
    name: "lever unilateral row",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "rope",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/emT6rbkqv1TYae",
    id: "0609",
    name: "london bridge",
    target: "upper back",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/p6MwZNPGwAhQQu",
    id: "3013",
    name: "low glute bridge on floor",
    target: "glutes",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/V6TwP8hq5HACbp",
    id: "1352",
    name: "lower back curl",
    target: "spine",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/15K-LTPnm-2-jU",
    id: "3582",
    name: "lunge with jump",
    target: "glutes",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/tiV93H2TBAlFOV",
    id: "1688",
    name: "lunge with twist",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/PUCLv3SCBwsFaI",
    id: "0613",
    name: "lying (side) quads stretch",
    target: "quads",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/K5U5wlWEMWw1k3",
    id: "2312",
    name: "lying elbow to knee",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/n6REE7WjPmzbSc",
    id: "0620",
    name: "lying leg raise flat bench",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Lxudz4zgRNzTes",
    id: "0865",
    name: "lying leg-hip raise",
    target: "abs",
  },
  {
    bodyPart: "chest",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/NWQDTewubKju8a",
    id: "1301",
    name: "machine inner chest press",
    target: "pectorals",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ylphLJF78BCoZO",
    id: "0624",
    name: "march sit (wall)",
    target: "glutes",
  },
  {
    bodyPart: "back",
    equipment: "medicine ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/q27AvfD2XPOMSD",
    id: "1353",
    name: "medicine ball catch and overhead throw",
    target: "lats",
  },
  {
    bodyPart: "chest",
    equipment: "medicine ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/zu8RciDEEXp1xt",
    id: "1302",
    name: "medicine ball chest pass",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "medicine ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/c4u5EbIVVxBnrA",
    id: "1303",
    name: "medicine ball chest push from 3 point stance",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "medicine ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/QGDXSOAkJ7UtEc",
    id: "1304",
    name: "medicine ball chest push multiple response",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "medicine ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/FgnOWX5fbeRYdw",
    id: "1305",
    name: "medicine ball chest push single response",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "medicine ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/h-F-K04JuPUpMi",
    id: "1312",
    name: "medicine ball chest push with run release",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "medicine ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Cu8ue2MTclfUk7",
    id: "1701",
    name: "medicine ball close grip push up",
    target: "triceps",
  },
  {
    bodyPart: "back",
    equipment: "medicine ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/mKz4UfBngLp7sq",
    id: "1354",
    name: "medicine ball overhead slam",
    target: "upper back",
  },
  {
    bodyPart: "upper arms",
    equipment: "medicine ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/jb0xG3iZRM8H9P",
    id: "1750",
    name: "medicine ball supine chest throw",
    target: "triceps",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/XaBOvn0WsL20ds",
    id: "0627",
    name: "mixed grip chin-up",
    target: "lats",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ckR3EqWMESHHzF",
    id: "3217",
    name: "modified hindu push-up (male)",
    target: "pectorals",
  },
  {
    bodyPart: "lower arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/eqTDJjVk3lxxd8",
    id: "1421",
    name: "modified push up to lower arms",
    target: "forearms",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/9Wd2t0bVczWK26",
    id: "0628",
    name: "monster walk",
    target: "glutes",
  },
  {
    bodyPart: "cardio",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/v33NjKwHFKnntz",
    id: "0630",
    name: "mountain climber",
    target: "cardiovascular system",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/DU93rw6GMN83xz",
    id: "0631",
    name: "muscle up",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/eYo1jYCgY0Pg27",
    id: "1401",
    name: "muscle-up (on vertical bar)",
    target: "lats",
  },
  {
    bodyPart: "upper arms",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/8qq8S85xiq7URg",
    id: "2328",
    name: "narrow push-up on exercise ball",
    target: "triceps",
  },
  {
    bodyPart: "neck",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Sn1jUZy7FQ9PHA",
    id: "1403",
    name: "neck side stretch",
    target: "levator scapulae",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/tzeWDt4G0-3QWN",
    id: "0634",
    name: "negative crunch",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/p7LI9oc7oGZUx1",
    id: "1495",
    name: "oblique crunch v. 2",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/AHMcoYjsRuZPoQ",
    id: "0635",
    name: "oblique crunches floor",
    target: "abs",
  },
  {
    bodyPart: "upper arms",
    equipment: "olympic barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/pO0b7icxvMai0Q",
    id: "0636",
    name: "olympic barbell hammer curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "olympic barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/mAtlQIiRBr59Bd",
    id: "0637",
    name: "olympic barbell triceps extension",
    target: "triceps",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ciGnM6GY8FKxhH",
    id: "1355",
    name: "one arm against wall",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ZAZJp6oVFX46Rt",
    id: "0638",
    name: "one arm chin-up",
    target: "lats",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/EnunLI8vcoMkDR",
    id: "0639",
    name: "one arm dip",
    target: "triceps",
  },
  {
    bodyPart: "waist",
    equipment: "medicine ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/pkoP89tfGEVukd",
    id: "0640",
    name: "one arm slam (with medicine ball)",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/bqbpaKk-KYUHsl",
    id: "1773",
    name: "one arm towel row",
    target: "upper back",
  },
  {
    bodyPart: "lower legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/uF5qXN1-2bQ3xA",
    id: "1386",
    name: "one leg donkey calf raise",
    target: "calves",
  },
  {
    bodyPart: "lower legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/C6caTGyBIOq5Tf",
    id: "1387",
    name: "one leg floor calf raise",
    target: "calves",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/u7n77SX4mWTTVq",
    id: "1476",
    name: "one leg squat",
    target: "glutes",
  },
  {
    bodyPart: "waist",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/9ncdrrQLV1iFY2",
    id: "0641",
    name: "otis up",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qAZLMW12ttTkiS",
    id: "0642",
    name: "outside leg kick push-up",
    target: "glutes",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/LZuK1DAhuhaYCW",
    id: "0643",
    name: "overhead triceps stretch",
    target: "triceps",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/FmTYLofmFoVXPd",
    id: "3147",
    name: "pelvic tilt",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/7z0rTwXkyuLZem",
    id: "1422",
    name: "pelvic tilt into bridge",
    target: "glutes",
  },
  {
    bodyPart: "lower legs",
    equipment: "rope",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/LJV5OrskTPMGsc",
    id: "1388",
    name: "peroneals stretch",
    target: "calves",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/tc6YvgzuV-glmK",
    id: "3662",
    name: "pike-to-cobra push-up",
    target: "glutes",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/0sAMqPwMmP1xRl",
    id: "1306",
    name: "plyo push up",
    target: "pectorals",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/vMV4xM2dEjArwQ",
    id: "1687",
    name: "posterior step to overhead reach",
    target: "abs",
  },
  {
    bodyPart: "lower legs",
    equipment: "rope",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/O4uP1hdZthwDpD",
    id: "1389",
    name: "posterior tibialis stretch",
    target: "calves",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Ar1Llrxe3WNNmI",
    id: "3119",
    name: "potty squat",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/FR3m9pSd3D49gq",
    id: "3132",
    name: "potty squat with support",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/-KpHzttyd9RN7D",
    id: "0648",
    name: "power clean",
    target: "hamstrings",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/PubPTm6dtxyXdn",
    id: "3665",
    name: "power point plank",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/g9L8SaXmoda28q",
    id: "3203",
    name: "prisoner half sit-up (male)",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/iVKZkrOHZIEERw",
    id: "1707",
    name: "prone twist on stability ball",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/h5Ln7iqde0ffXb",
    id: "0651",
    name: "pull up (neutral grip)",
    target: "lats",
  },
  {
    bodyPart: "waist",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/S4fk-XzAxYfiSt",
    id: "0650",
    name: "pull-in (on stability ball)",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/7gqu23GG8K1hVz",
    id: "0652",
    name: "pull-up",
    target: "lats",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/2phS-kghHrLgKd",
    id: "1689",
    name: "push and pull bodyweight",
    target: "pectorals",
  },
  {
    bodyPart: "cardio",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/eK-yfF34YrkWO6",
    id: "3638",
    name: "push to run",
    target: "cardiovascular system",
  },
  {
    bodyPart: "chest",
    equipment: "bosu ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/---3s8PYMs71mV",
    id: "1307",
    name: "push up on bosu ball",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/dWr7dWkD6V-WGa",
    id: "0662",
    name: "push-up",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "bosu ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/VrN8IWCZU0DcSO",
    id: "0653",
    name: "push-up (bosu ball)",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/dllIo070NB7YoY",
    id: "0655",
    name: "push-up (on stability ball)",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/UYS5u4oOStvRyU",
    id: "0656",
    name: "push-up (on stability ball)",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/7a2NrOiegHCPKM",
    id: "0659",
    name: "push-up (wall)",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Jx7P9rXnCAjSIQ",
    id: "0658",
    name: "push-up (wall) v. 2",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/NhZ02sVmqNVVjy",
    id: "0660",
    name: "push-up close-grip off dumbbell",
    target: "triceps",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qGIEkqj7zNb3je",
    id: "0661",
    name: "push-up inside leg kick",
    target: "glutes",
  },
  {
    bodyPart: "chest",
    equipment: "medicine ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Zpw-y2slQGDV30",
    id: "0663",
    name: "push-up medicine ball",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/VmBG3rYQkpHFVl",
    id: "1467",
    name: "push-up on lower arms",
    target: "triceps",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/TIVbmoypBUUr2q",
    id: "3145",
    name: "push-up plus",
    target: "pectorals",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ro8rTQ4oQnDNk6",
    id: "0664",
    name: "push-up to side plank",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Fvn9Fdy3uA55KZ",
    id: "3533",
    name: "quads",
    target: "quads",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/HlYXIXyjL5j6hU",
    id: "3201",
    name: "quarter sit-up",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/TyNpx0A5OxqGTT",
    id: "3552",
    name: "quick feet v. 2",
    target: "quads",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/DKQMvRUVLDVUMc",
    id: "0666",
    name: "raise single arm push-up",
    target: "pectorals",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/DjUP9a4emRaKs2",
    id: "0668",
    name: "rear decline bridge",
    target: "glutes",
  },
  {
    bodyPart: "shoulders",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/7WxEUknEXtODCu",
    id: "0669",
    name: "rear deltoid stretch",
    target: "delts",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Q8NP9qikPpK4Kn",
    id: "0670",
    name: "rear pull-up",
    target: "lats",
  },
  {
    bodyPart: "upper legs",
    equipment: "rope",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/rWENm6BJa91M0y",
    id: "1582",
    name: "reclining big toe pose with rope",
    target: "hamstrings",
  },
  {
    bodyPart: "upper legs",
    equipment: "resistance band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kX8niNAIqdoHKm",
    id: "3236",
    name: "resistance band hip thrusts on knees (female)",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "resistance band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/T2hFbg6L2D3YgL",
    id: "3007",
    name: "resistance band leg extension",
    target: "quads",
  },
  {
    bodyPart: "upper arms",
    equipment: "resistance band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/YtwZ6FCxgrNUYK",
    id: "3123",
    name: "resistance band seated biceps curl",
    target: "biceps",
  },
  {
    bodyPart: "chest",
    equipment: "resistance band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/hsqdrcXyUXleHe",
    id: "3124",
    name: "resistance band seated chest press",
    target: "pectorals",
  },
  {
    bodyPart: "upper legs",
    equipment: "resistance band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/bAbOIRmDbMsYC-",
    id: "3006",
    name: "resistance band seated hip abduction",
    target: "abductors",
  },
  {
    bodyPart: "shoulders",
    equipment: "resistance band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/wzEbgBV5kL8Q74",
    id: "3122",
    name: "resistance band seated shoulder press",
    target: "delts",
  },
  {
    bodyPart: "back",
    equipment: "resistance band",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/LhRjQl6CsjKbLd",
    id: "3144",
    name: "resistance band seated straight back row",
    target: "upper back",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/7kR4V1WoiftKZn",
    id: "0872",
    name: "reverse crunch",
    target: "abs",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/rwg9c2yvn2k5jv",
    id: "0672",
    name: "reverse dip",
    target: "triceps",
  },
  {
    bodyPart: "back",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/XSyvYadvB9AIxq",
    id: "0673",
    name: "reverse grip machine lat pulldown",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/zgZjHyeW0Jlp4a",
    id: "0674",
    name: "reverse grip pull-up",
    target: "lats",
  },
  {
    bodyPart: "upper legs",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/nEZ8OBYAk2ncw8",
    id: "0675",
    name: "reverse hyper extension (on stability ball)",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/QZK80uyCDPr5vZ",
    id: "1423",
    name: "reverse hyper on flat bench",
    target: "glutes",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/fvwtsKKVDF9Pm2",
    id: "3663",
    name: "reverse plank with leg lift",
    target: "abs",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/PXyX43ismHhEMh",
    id: "0677",
    name: "ring dips",
    target: "triceps",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/3yybyOKv9H4D3i",
    id: "2571",
    name: "rocking frog stretch",
    target: "glutes",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/DO5DbZfoMAo-wA",
    id: "0678",
    name: "rocky pull-up pulldown",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "roller",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/F3C7ldd7jW49YV",
    id: "2208",
    name: "roller back stretch",
    target: "spine",
  },
  {
    bodyPart: "waist",
    equipment: "roller",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/MaobsZwZYF1Awi",
    id: "2204",
    name: "roller body saw",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "roller",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/G63SbRzIknL4aS",
    id: "2205",
    name: "roller hip lat stretch",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "roller",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/eeo2THAvmbMqhV",
    id: "2202",
    name: "roller hip stretch",
    target: "glutes",
  },
  {
    bodyPart: "waist",
    equipment: "roller",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/35I9ohTJXmCDDS",
    id: "2206",
    name: "roller reverse crunch",
    target: "abs",
  },
  {
    bodyPart: "chest",
    equipment: "roller",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/1LWMdfnUkEikSL",
    id: "2203",
    name: "roller seated shoulder flexor depresor retractor",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "roller",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/aRpXj9D8JcqXAc",
    id: "2209",
    name: "roller seated single leg shoulder flexor depresor retractor",
    target: "pectorals",
  },
  {
    bodyPart: "back",
    equipment: "roller",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/5oszlgwF4HVOyj",
    id: "2207",
    name: "roller side lat stretch",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "rope",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/WwhDdn57NFgmWV",
    id: "0680",
    name: "rope climb",
    target: "upper back",
  },
  {
    bodyPart: "cardio",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/PSePFYkBgNymbg",
    id: "0685",
    name: "run",
    target: "cardiovascular system",
  },
  {
    bodyPart: "cardio",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/yiekbeVC3Df5CH",
    id: "0684",
    name: "run (equipment)",
    target: "cardiovascular system",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/8rnUVNyuUVeQic",
    id: "1585",
    name: "runners stretch",
    target: "hamstrings",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/8-im3bjt9w6WrR",
    id: "0687",
    name: "russian twist",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/gzIwQq9iFVdfps",
    id: "3012",
    name: "scapula dips",
    target: "traps",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/c9NpjxLJyKAMyb",
    id: "3021",
    name: "scapula push-up",
    target: "serratus anterior",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/g8c6ubXnWuGbUj",
    id: "0688",
    name: "scapular pull-up",
    target: "traps",
  },
  {
    bodyPart: "cardio",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Md9hD4vfIdV0iy",
    id: "3219",
    name: "scissor jumps (male)",
    target: "cardiovascular system",
  },
  {
    bodyPart: "lower legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/hMyLKxwcrXcizg",
    id: "1390",
    name: "seated calf stretch (male)",
    target: "calves",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/6Lnw5m3oww9dYp",
    id: "1424",
    name: "seated glute stretch",
    target: "glutes",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/opruNPJDTQXS5i",
    id: "0689",
    name: "seated leg raise",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/IaoXKno3T7sKgK",
    id: "0690",
    name: "seated lower back stretch",
    target: "lats",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/N1FfT2WHnheWcm",
    id: "2567",
    name: "seated piriformis stretch",
    target: "glutes",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/S8BJdBEVUcTsXE",
    id: "0691",
    name: "seated side crunch (wall)",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/GcoyA9cnP1r374",
    id: "1587",
    name: "seated wide angle pose sequence",
    target: "hamstrings",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/OywcJdeB5Y79cB",
    id: "0697",
    name: "self assisted inverse leg curl",
    target: "hamstrings",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ceGKp6Jo7coV9Q",
    id: "1766",
    name: "self assisted inverse leg curl",
    target: "hamstrings",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/TF98Nwp9WvdQUS",
    id: "0696",
    name: "self assisted inverse leg curl (on floor)",
    target: "hamstrings",
  },
  {
    bodyPart: "cardio",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/T45sukC46BlpUc",
    id: "3222",
    name: "semi squat jump (male)",
    target: "cardiovascular system",
  },
  {
    bodyPart: "cardio",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/yX-C4H-JBx9-29",
    id: "3656",
    name: "short stride run",
    target: "cardiovascular system",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/q-8B3CtSK8nIsG",
    id: "1763",
    name: "shoulder grip pull-up",
    target: "lats",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ZnDYeItNCqB3Ta",
    id: "3699",
    name: "shoulder tap",
    target: "abs",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/evGjU87Wq69BA1",
    id: "0699",
    name: "shoulder tap push-up",
    target: "pectorals",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/n563eS9roRsydK",
    id: "1774",
    name: "side bridge hip abduction",
    target: "abductors",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/4uWAbLJmJ00GJD",
    id: "0705",
    name: "side bridge v. 2",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Bo4sG3TLQ46tnJ",
    id: "0709",
    name: "side hip (on parallel bars)",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/08uDZzXX9wCr05",
    id: "0710",
    name: "side hip abduction",
    target: "abductors",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qLEj47otrHozgm",
    id: "1358",
    name: "side lying floor stretch",
    target: "lats",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/lKwUHj2Jo8Q8Vj",
    id: "3667",
    name: "side lying hip adduction (male)",
    target: "adductors",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/LXjdkMM0CpeavL",
    id: "1775",
    name: "side plank hip adduction",
    target: "adductors",
  },
  {
    bodyPart: "neck",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/vCHEKzsMBUyh4X",
    id: "0716",
    name: "side push neck stretch",
    target: "levator scapulae",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/v-ZE0lwJYbRDn0",
    id: "0717",
    name: "side push-up",
    target: "triceps",
  },
  {
    bodyPart: "lower arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/AcoMX-y-rZjY1O",
    id: "0721",
    name: "side wrist pull stretch",
    target: "forearms",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/alTopqk3h4DKHY",
    id: "0720",
    name: "side-to-side chin",
    target: "lats",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/eIhCq7MQJmIEdy",
    id: "3213",
    name: "side-to-side toe touch (male)",
    target: "abs",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/t56UTClr6bdQC1",
    id: "0725",
    name: "single arm push-up",
    target: "pectorals",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/tnXFTQd3Ci3Oqg",
    id: "3645",
    name: "single leg bridge with outstretched leg",
    target: "glutes",
  },
  {
    bodyPart: "lower legs",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/uieCfEysYxTJtY",
    id: "0727",
    name: "single leg calf raise (on a dumbbell)",
    target: "calves",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/2HUt3F0PbWaK9D",
    id: "0730",
    name: "single leg platform slide",
    target: "hamstrings",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/pPalKiQkYhbcgn",
    id: "1759",
    name: "single leg squat (pistol) male",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/SSPteEY9fqzbdH",
    id: "1489",
    name: "sissy squat",
    target: "quads",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/epV5swVfUUSc6g",
    id: "0735",
    name: "sit-up v. 2",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/umhvrchMufJT-A",
    id: "3679",
    name: "sit-up with arms on chest",
    target: "abs",
  },
  {
    bodyPart: "cardio",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/mNhiuxZpOdZACS",
    id: "3361",
    name: "skater hops",
    target: "cardiovascular system",
  },
  {
    bodyPart: "upper arms",
    equipment: "skierg machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/lkVufPWmCQss1-",
    id: "2142",
    name: "ski ergometer",
    target: "triceps",
  },
  {
    bodyPart: "cardio",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qiOpILX8eLixRA",
    id: "3671",
    name: "ski step",
    target: "cardiovascular system",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/dH2yP5uzuagbyZ",
    id: "3304",
    name: "skin the cat",
    target: "upper back",
  },
  {
    bodyPart: "upper legs",
    equipment: "sled machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/bqzjf0pE9ZpDSE",
    id: "1425",
    name: "sled 45 degrees one leg press",
    target: "glutes",
  },
  {
    bodyPart: "lower legs",
    equipment: "sled machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/t5a9mHIwmFfIyb",
    id: "0738",
    name: "sled 45в° calf press",
    target: "calves",
  },
  {
    bodyPart: "upper legs",
    equipment: "sled machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Glvq-iduvx0IaY",
    id: "0739",
    name: "sled 45в° leg press",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "sled machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/IUPxTsfwTnl8Vs",
    id: "1464",
    name: "sled 45в° leg press (back pov)",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "sled machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/CYCH3i74AaWm6v",
    id: "1463",
    name: "sled 45в° leg press (side pov)",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "sled machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/t92ZPc5ct0K6xx",
    id: "0740",
    name: "sled 45в° leg wide press",
    target: "glutes",
  },
  {
    bodyPart: "lower legs",
    equipment: "sled machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/375RJTM-wz1NKo",
    id: "1391",
    name: "sled calf press on leg press",
    target: "calves",
  },
  {
    bodyPart: "upper legs",
    equipment: "sled machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/QLVH1Mc05aeEV0",
    id: "0741",
    name: "sled closer hack squat",
    target: "glutes",
  },
  {
    bodyPart: "lower legs",
    equipment: "sled machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qQM7Ae7dKVw2Jb",
    id: "0742",
    name: "sled forward angled calf raise",
    target: "calves",
  },
  {
    bodyPart: "upper legs",
    equipment: "sled machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/auGggsbs7BET4k",
    id: "0743",
    name: "sled hack squat",
    target: "glutes",
  },
  {
    bodyPart: "lower legs",
    equipment: "sled machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/hybprkIvmu1qlH",
    id: "2334",
    name: "sled lying calf press",
    target: "calves",
  },
  {
    bodyPart: "upper legs",
    equipment: "sled machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/-Q6MCIX4FAySkI",
    id: "0744",
    name: "sled lying squat",
    target: "glutes",
  },
  {
    bodyPart: "lower legs",
    equipment: "sled machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/JDUED4CA3pgnYp",
    id: "1392",
    name: "sled one leg calf press on leg press",
    target: "calves",
  },
  {
    bodyPart: "waist",
    equipment: "hammer",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/c9WzgWvg1sMILR",
    id: "1496",
    name: "sledge hammer",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kOjXnfvk0C8umb",
    id: "0746",
    name: "smith back shrug",
    target: "traps",
  },
  {
    bodyPart: "shoulders",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/CWZrotxLu74dbZ",
    id: "0747",
    name: "smith behind neck press",
    target: "delts",
  },
  {
    bodyPart: "chest",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/N7pgyfypzrvq-V",
    id: "0748",
    name: "smith bench press",
    target: "pectorals",
  },
  {
    bodyPart: "upper legs",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/rQYk4v99O909oa",
    id: "0749",
    name: "smith bent knee good morning",
    target: "glutes",
  },
  {
    bodyPart: "back",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/m2Cg06fxRb4IZi",
    id: "1359",
    name: "smith bent over row",
    target: "upper back",
  },
  {
    bodyPart: "upper legs",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/QCUNInhH4w1jy-",
    id: "0750",
    name: "smith chair squat",
    target: "quads",
  },
  {
    bodyPart: "upper arms",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/6G8NUA7I5XD5vn",
    id: "0751",
    name: "smith close-grip bench press",
    target: "triceps",
  },
  {
    bodyPart: "upper legs",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/FeGIC04B94J0d4",
    id: "0752",
    name: "smith deadlift",
    target: "glutes",
  },
  {
    bodyPart: "chest",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/f5ZTZM3cgZ9d7b",
    id: "0753",
    name: "smith decline bench press",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/lcOfk4PASyyqO0",
    id: "0754",
    name: "smith decline reverse-grip press",
    target: "pectorals",
  },
  {
    bodyPart: "upper legs",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/TOxUw6USxpSE05",
    id: "1433",
    name: "smith front squat (clean grip)",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/FXSCHxW0cDyUA5",
    id: "3281",
    name: "smith full squat",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/K99g6afazGzRCe",
    id: "0755",
    name: "smith hack squat",
    target: "glutes",
  },
  {
    bodyPart: "waist",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/4EumN75JmixDpI",
    id: "0756",
    name: "smith hip raise",
    target: "abs",
  },
  {
    bodyPart: "chest",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/4rr5wkBPKLl0Lw",
    id: "0757",
    name: "smith incline bench press",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/YoXCh70E93Y8uk",
    id: "0758",
    name: "smith incline reverse-grip press",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/FqmgZgvJodTlEM",
    id: "0759",
    name: "smith incline shoulder raises",
    target: "serratus anterior",
  },
  {
    bodyPart: "upper legs",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/o2uds9Iw9FILj-",
    id: "0760",
    name: "smith leg press",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/u48Ri20Mx4SN-Y",
    id: "1434",
    name: "smith low bar squat",
    target: "glutes",
  },
  {
    bodyPart: "upper arms",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/w1vM5tXV5Esipf",
    id: "1683",
    name: "smith machine bicep curl",
    target: "biceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/-n4fCMsBtjWkM9",
    id: "1625",
    name: "smith machine decline close grip bench press",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/hF4gAUAq4F0QSu",
    id: "1752",
    name: "smith machine incline tricep extension",
    target: "triceps",
  },
  {
    bodyPart: "chest",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/l99CnnWEXnL203",
    id: "1626",
    name: "smith machine reverse decline close grip bench press",
    target: "pectorals",
  },
  {
    bodyPart: "back",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/P15YOQt3MZEDJG",
    id: "0761",
    name: "smith narrow row",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/DFcRP6udCnyB7W",
    id: "1360",
    name: "smith one arm row",
    target: "upper back",
  },
  {
    bodyPart: "lower legs",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/JVRAIBcDln7Bki",
    id: "1393",
    name: "smith one leg floor calf raise",
    target: "calves",
  },
  {
    bodyPart: "shoulders",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/LIGaHWgVQxsqUV",
    id: "0762",
    name: "smith rear delt row",
    target: "delts",
  },
  {
    bodyPart: "lower legs",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/p7sXPWcBrQCZ94",
    id: "0763",
    name: "smith reverse calf raises",
    target: "calves",
  },
  {
    bodyPart: "lower legs",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/XwFkOkBvy57a2U",
    id: "1394",
    name: "smith reverse calf raises",
    target: "calves",
  },
  {
    bodyPart: "back",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/a06LY7dT2b32wP",
    id: "1361",
    name: "smith reverse grip bent over row",
    target: "upper back",
  },
  {
    bodyPart: "chest",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/nNWvj7Zw9J7PyW",
    id: "0764",
    name: "smith reverse-grip press",
    target: "pectorals",
  },
  {
    bodyPart: "lower legs",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/t0V7RRrwn9UCi7",
    id: "1395",
    name: "smith seated one leg calf raise",
    target: "calves",
  },
  {
    bodyPart: "shoulders",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/gpWUK-5itRqSN-",
    id: "0765",
    name: "smith seated shoulder press",
    target: "delts",
  },
  {
    bodyPart: "lower arms",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/YhnLagZ1JfIbdb",
    id: "1426",
    name: "smith seated wrist curl",
    target: "forearms",
  },
  {
    bodyPart: "shoulders",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/KfLzBfhgl-VgVu",
    id: "0766",
    name: "smith shoulder press",
    target: "delts",
  },
  {
    bodyPart: "back",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/QY4cIMNe5Buyn6",
    id: "0767",
    name: "smith shrug",
    target: "traps",
  },
  {
    bodyPart: "upper legs",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/FQbzKTjwprit7n",
    id: "0768",
    name: "smith single leg split squat",
    target: "quads",
  },
  {
    bodyPart: "upper legs",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/nTci50zJMRpJlQ",
    id: "0769",
    name: "smith sprint lunge",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/83DXBwpt4vKOEx",
    id: "0770",
    name: "smith squat",
    target: "glutes",
  },
  {
    bodyPart: "lower arms",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/3ZhbMyR6ulF7iT",
    id: "0771",
    name: "smith standing back wrist curl",
    target: "forearms",
  },
  {
    bodyPart: "shoulders",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Mpg1XU-Pk0rmhg",
    id: "0772",
    name: "smith standing behind head military press",
    target: "delts",
  },
  {
    bodyPart: "lower legs",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/fHFLReSWczfoEN",
    id: "0773",
    name: "smith standing leg calf raise",
    target: "calves",
  },
  {
    bodyPart: "shoulders",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/eIeg-JJjhmm8Rr",
    id: "0774",
    name: "smith standing military press",
    target: "delts",
  },
  {
    bodyPart: "upper legs",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/tMe6nEc-6GGLz1",
    id: "3142",
    name: "smith sumo squat",
    target: "glutes",
  },
  {
    bodyPart: "lower legs",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Gm7QTLFOsy3IJr",
    id: "1396",
    name: "smith toe raise",
    target: "calves",
  },
  {
    bodyPart: "shoulders",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/OJfjkLptc8hjhJ",
    id: "0775",
    name: "smith upright row",
    target: "delts",
  },
  {
    bodyPart: "chest",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ugkWK-cJgCicFy",
    id: "1308",
    name: "smith wide grip bench press",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "smith machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/KPIwoB-VaDNnB8",
    id: "1309",
    name: "smith wide grip decline bench press",
    target: "pectorals",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/deK7q3WkeSeZ4D",
    id: "0776",
    name: "snatch pull",
    target: "quads",
  },
  {
    bodyPart: "waist",
    equipment: "dumbbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kqlhhEVfjuj61o",
    id: "0777",
    name: "spell caster",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qcJSGOHf3RHRG8",
    id: "1362",
    name: "sphinx",
    target: "spine",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/tRZS4daqvnYtAc",
    id: "0778",
    name: "spider crawl push up",
    target: "glutes",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/63EXNj3EgcpIT0",
    id: "1363",
    name: "spine stretch",
    target: "spine",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/UHoOixkotDct0G",
    id: "2329",
    name: "spine twist",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Lcvcr-uayfOv71",
    id: "2368",
    name: "split squats",
    target: "quads",
  },
  {
    bodyPart: "upper legs",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/wBGekszZkJ2VWG",
    id: "0786",
    name: "squat jerk",
    target: "quads",
  },
  {
    bodyPart: "upper legs",
    equipment: "bosu ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ys3UbfLNlnbh3W",
    id: "1705",
    name: "squat on bosu ball",
    target: "quads",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/8MrSP3UmHG9dWn",
    id: "1685",
    name: "squat to overhead reach",
    target: "quads",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/UD8vfQOcoP-Nz9",
    id: "1686",
    name: "squat to overhead reach with twist",
    target: "quads",
  },
  {
    bodyPart: "waist",
    equipment: "stability ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/A04icGhbduuHxq",
    id: "2297",
    name: "stability ball crunch (full range hands behind head)",
    target: "abs",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/A6KRRYUH7Ca2Sy",
    id: "3291",
    name: "stalder press",
    target: "triceps",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/JnIToy8b1MPCh8",
    id: "3669",
    name: "standing archer",
    target: "upper back",
  },
  {
    bodyPart: "shoulders",
    equipment: "barbell",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Fjn6ApE6CqsSWF",
    id: "0788",
    name: "standing behind neck press",
    target: "delts",
  },
  {
    bodyPart: "lower legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/OI-NMbo6cB3acS",
    id: "1490",
    name: "standing calf raise (on a staircase)",
    target: "calves",
  },
  {
    bodyPart: "lower legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Ux9aBZJy0llKYN",
    id: "1397",
    name: "standing calves",
    target: "calves",
  },
  {
    bodyPart: "lower legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/JI0FPNLtiv-f-v",
    id: "1398",
    name: "standing calves calf stretch",
    target: "calves",
  },
  {
    bodyPart: "upper legs",
    equipment: "rope",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/jk5phSQaC8iUky",
    id: "1599",
    name: "standing hamstring and calf stretch with strap",
    target: "hamstrings",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/pWy6OqXvY9ZAYR",
    id: "0794",
    name: "standing lateral stretch",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/YFIxS74FrrY9t4",
    id: "1364",
    name: "standing pelvic tilt",
    target: "spine",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/pjJx60bCAExGCW",
    id: "0795",
    name: "standing single leg curl",
    target: "hamstrings",
  },
  {
    bodyPart: "waist",
    equipment: "wheel roller",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/BVm8zBT3a7w-h8",
    id: "0796",
    name: "standing wheel rollerout",
    target: "abs",
  },
  {
    bodyPart: "cardio",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/i8jqyYEYiXMc7Y",
    id: "3223",
    name: "star jump (male)",
    target: "cardiovascular system",
  },
  {
    bodyPart: "cardio",
    equipment: "stationary bike",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/klSLb1bxsLb73H",
    id: "2138",
    name: "stationary bike run v. 3",
    target: "cardiovascular system",
  },
  {
    bodyPart: "cardio",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/837LxQDihCsR8T",
    id: "0798",
    name: "stationary bike walk",
    target: "cardiovascular system",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/2KOh3d0M7jPS5S",
    id: "3314",
    name: "straddle maltese",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/vVhD4bSzUWCV2a",
    id: "3298",
    name: "straddle planche",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ugjFKqIgAni0pS",
    id: "1427",
    name: "straight leg outer hip abductor",
    target: "abductors",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/R4DOLVRj4LCu3A",
    id: "0803",
    name: "superman push-up",
    target: "pectorals",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Dt2hS0N36uAq2i",
    id: "0805",
    name: "suspended abdominal fallout",
    target: "abs",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/LqBXjnQuC97BVq",
    id: "0806",
    name: "suspended push-up",
    target: "pectorals",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/-R8CqQGLT5ugvr",
    id: "0807",
    name: "suspended reverse crunch",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/bmjgoXQnVtajmA",
    id: "0808",
    name: "suspended row",
    target: "upper back",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/3tETHsXlTsAMct",
    id: "0809",
    name: "suspended split squat",
    target: "quads",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/g5sih7HglHqMlP",
    id: "3433",
    name: "swimmer kicks v. 2 (male)",
    target: "glutes",
  },
  {
    bodyPart: "cardio",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/IYz6ezKZqB2Z0I",
    id: "3318",
    name: "swing 360",
    target: "cardiovascular system",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/uEfn37aEz2zSM4",
    id: "1753",
    name: "three bench dip",
    target: "triceps",
  },
  {
    bodyPart: "upper legs",
    equipment: "tire",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ytshDLEujT9xZw",
    id: "2459",
    name: "tire flip",
    target: "glutes",
  },
  {
    bodyPart: "upper legs",
    equipment: "trap bar",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/QzSLc6PLJx8YwC",
    id: "0811",
    name: "trap bar deadlift",
    target: "glutes",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/xqtGLa6q3uK3mP",
    id: "0814",
    name: "triceps dip",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/f3vE4NxaHBkUgo",
    id: "0812",
    name: "triceps dip (bench leg)",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/b3xkSmC-AI68eR",
    id: "0813",
    name: "triceps dip (between benches)",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ByWkmY3pEbsz49",
    id: "0815",
    name: "triceps dips floor",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/7rHdWbrUL5KK9v",
    id: "0816",
    name: "triceps press",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/1N6B2IdZ9FhS9V",
    id: "0817",
    name: "triceps stretch",
    target: "triceps",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Q3ifN6ACrJ-rr6",
    id: "0871",
    name: "tuck crunch",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "cable",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/lOjImfucrhvsNr",
    id: "0818",
    name: "twin handle parallel grip lat pulldown",
    target: "lats",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/8B5GfvWC8nGYpC",
    id: "1466",
    name: "twist hip lift",
    target: "glutes",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/jNVLf255jAGrip",
    id: "2802",
    name: "twisted leg raise",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/e7ym-YunfUeNGU",
    id: "2801",
    name: "twisted leg raise (female)",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/O5T5fsAudShqu6",
    id: "3231",
    name: "two toe touch (male)",
    target: "spine",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/wtqSn9aHwYB3U7",
    id: "1365",
    name: "upper back stretch",
    target: "upper back",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/DbtlkwIXhbmUO-",
    id: "1366",
    name: "upward facing dog",
    target: "spine",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/v8g801bN2DkC7k",
    id: "3420",
    name: "v-sit on floor",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/h2koc4Qsc6-0pg",
    id: "0826",
    name: "vertical leg raise (on parallel bars)",
    target: "abs",
  },
  {
    bodyPart: "cardio",
    equipment: "elliptical machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/tXquoP6fhV8jUf",
    id: "2141",
    name: "walk elliptical cross trainer",
    target: "cardiovascular system",
  },
  {
    bodyPart: "cardio",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/vGz2sirEb9mT8g",
    id: "3655",
    name: "walking high knees lunge",
    target: "cardiovascular system",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/bihoEJenk4Ur0v",
    id: "1460",
    name: "walking lunge",
    target: "glutes",
  },
  {
    bodyPart: "cardio",
    equipment: "leverage machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/rgXjTZ6oVIaTR1",
    id: "3666",
    name: "walking on incline treadmill",
    target: "cardiovascular system",
  },
  {
    bodyPart: "cardio",
    equipment: "stepmill machine",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/xbQnMkqPzhPB2G",
    id: "2311",
    name: "walking on stepmill",
    target: "cardiovascular system",
  },
  {
    bodyPart: "upper arms",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/gsdTzi5ShR66Ea",
    id: "0830",
    name: "weighted bench dip",
    target: "triceps",
  },
  {
    bodyPart: "back",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/evnfZM3a9b6BC7",
    id: "2987",
    name: "weighted close grip chin-up on dip cage",
    target: "lats",
  },
  {
    bodyPart: "upper legs",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/hXQekACCXU99lL",
    id: "3643",
    name: "weighted cossack squats (male)",
    target: "glutes",
  },
  {
    bodyPart: "waist",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/LIwRgpR0GN1Nwa",
    id: "0832",
    name: "weighted crunch",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/3M8tpTsMHYtPa2",
    id: "3670",
    name: "weighted decline sit-up",
    target: "abs",
  },
  {
    bodyPart: "lower legs",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/1VaDQGZ0VlU8JT",
    id: "0833",
    name: "weighted donkey calf raise",
    target: "calves",
  },
  {
    bodyPart: "chest",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/EQBC9mHzbAZoTA",
    id: "1310",
    name: "weighted drop push up",
    target: "pectorals",
  },
  {
    bodyPart: "waist",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/y9dLWP1vy8UYfO",
    id: "2135",
    name: "weighted front plank",
    target: "abs",
  },
  {
    bodyPart: "shoulders",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Z8tx6jIgR-d1P3",
    id: "0834",
    name: "weighted front raise",
    target: "delts",
  },
  {
    bodyPart: "waist",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/oQFEMd581NL97T",
    id: "0866",
    name: "weighted hanging leg-hip raise",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/AOs0xbGpmp0-t0",
    id: "0835",
    name: "weighted hyperextension (on stability ball)",
    target: "spine",
  },
  {
    bodyPart: "shoulders",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/qF4j8ikz1ql9Dt",
    id: "3641",
    name: "weighted kneeling step with swing",
    target: "delts",
  },
  {
    bodyPart: "upper legs",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/sQsso9HcPMWDAK",
    id: "3644",
    name: "weighted lunge with swing",
    target: "glutes",
  },
  {
    bodyPart: "back",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/4pBl1I5jmCdzL9",
    id: "3286",
    name: "weighted muscle up",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/8yiTjCX1E4moBq",
    id: "3312",
    name: "weighted muscle up (on bar)",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/GpqH0hi-eHVqE4",
    id: "3290",
    name: "weighted one hand pull up",
    target: "lats",
  },
  {
    bodyPart: "waist",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/GRAxaDXpzBfq68",
    id: "0840",
    name: "weighted overhead crunch (on stability ball)",
    target: "abs",
  },
  {
    bodyPart: "back",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/NcMxAHxUczkSZ3",
    id: "0841",
    name: "weighted pull-up",
    target: "lats",
  },
  {
    bodyPart: "shoulders",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/OjUpnsXYxFqCJ4",
    id: "0844",
    name: "weighted round arm",
    target: "delts",
  },
  {
    bodyPart: "waist",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/KwdTGL8f-S2hqy",
    id: "0846",
    name: "weighted russian twist",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Uob7tTjg3tg4sc",
    id: "0845",
    name: "weighted russian twist (legs up)",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/N86zROBbpAkCqC",
    id: "2371",
    name: "weighted russian twist v. 2",
    target: "abs",
  },
  {
    bodyPart: "upper arms",
    equipment: "medicine ball",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/YyTbCWdiTxjO7H",
    id: "0847",
    name: "weighted seated bicep curl  (on stability ball)",
    target: "biceps",
  },
  {
    bodyPart: "waist",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/ujAr3AfAkE2TKp",
    id: "0849",
    name: "weighted seated twist (on stability ball)",
    target: "abs",
  },
  {
    bodyPart: "waist",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kDBSdLP27PhL-B",
    id: "0850",
    name: "weighted side bend (on stability ball)",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/F8lF9wE4u5M7zv",
    id: "0851",
    name: "weighted sissy squat",
    target: "quads",
  },
  {
    bodyPart: "upper legs",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/3ckIwqIXZxv3p5",
    id: "0852",
    name: "weighted squat",
    target: "glutes",
  },
  {
    bodyPart: "upper arms",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/o81DPG88YoFAAE",
    id: "0853",
    name: "weighted standing curl",
    target: "biceps",
  },
  {
    bodyPart: "lower arms",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/TYmR2t8i0pAaSK",
    id: "0854",
    name: "weighted standing hand squeeze",
    target: "forearms",
  },
  {
    bodyPart: "chest",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/dVEnvApvcBCZRY",
    id: "3313",
    name: "weighted straight bar dip",
    target: "pectorals",
  },
  {
    bodyPart: "upper legs",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/CA24-qIuLfwO2h",
    id: "3642",
    name: "weighted stretch lunge",
    target: "glutes",
  },
  {
    bodyPart: "chest",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/HGH5h-5Ncip6cw",
    id: "0856",
    name: "weighted svend press",
    target: "pectorals",
  },
  {
    bodyPart: "upper arms",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/W0fKI3-NUdAQcE",
    id: "1754",
    name: "weighted three bench dips",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Ilj3PhBVU6lUVj",
    id: "1755",
    name: "weighted tricep dips",
    target: "triceps",
  },
  {
    bodyPart: "upper arms",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/fshq5qQ3SLWjha",
    id: "1767",
    name: "weighted triceps dip on high parallel bars",
    target: "triceps",
  },
  {
    bodyPart: "waist",
    equipment: "wheel roller",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/kfEdBrU1v3TLsm",
    id: "0857",
    name: "wheel rollerout",
    target: "abs",
  },
  {
    bodyPart: "cardio",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/sFjTetfOuKy0Ps",
    id: "3637",
    name: "wheel run",
    target: "cardiovascular system",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Hlefm83PBFokqQ",
    id: "1429",
    name: "wide grip pull-up",
    target: "lats",
  },
  {
    bodyPart: "back",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/vU5ZVIfxJutBDC",
    id: "1367",
    name: "wide grip rear pull-up",
    target: "lats",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/MdjkrhmlHOR7qq",
    id: "1311",
    name: "wide hand push up",
    target: "pectorals",
  },
  {
    bodyPart: "chest",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/Lxg-jwCw80nMJ-",
    id: "2363",
    name: "wide-grip chest dip on high parallel bars",
    target: "pectorals",
  },
  {
    bodyPart: "waist",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/F3oyUMyQJsSKJm",
    id: "0858",
    name: "wind sprints",
    target: "abs",
  },
  {
    bodyPart: "upper legs",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/n6A8ERZqx95qUV",
    id: "1604",
    name: "world greatest stretch",
    target: "hamstrings",
  },
  {
    bodyPart: "lower arms",
    equipment: "body weight",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/joWzsrP-bfyMs0",
    id: "1428",
    name: "wrist circles",
    target: "forearms",
  },
  {
    bodyPart: "lower arms",
    equipment: "weighted",
    gifUrl: "https://edb-4rme8.ondigitalocean.app/image/u0B41qHiac8Awn",
    id: "0859",
    name: "wrist rollerer",
    target: "forearms",
  },
];
